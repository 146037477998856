import gql from 'graphql-tag'

export default {
  GET_USERS_PAYMENT: gql`
    query getUsers(
      $page: Int
      $first: Int
      $companyId: [String!]
      $projectId: [String!]
    ) {
      users(
        first: $first
        page: $page
        project: { searchKey: UUID, keyword: $projectId }
      ) {
        data {
          name
          email
          id
          uuid
          avatar
          companyRoles(
            company: { searchKey: UUID, keyword: $companyId }
            first: -1
          ) {
            data {
              role {
                name
                displayName
              }
            }
          }
          paymentSettings(
            project: { searchKey: UUID, keyword: $projectId }
            first: 3
          ) {
            data {
              amount
              paymentType
              effectiveFrom
              effectiveTo
            }
          }
        }
        total
      }
    }
  `,

  GET_PAYMENT_USER: gql`
    query paymentUser($page: Int, $first: Int, $projectUUid: [String!], $startTime: DateTime!, $endTime: DateTime!, $timezone: String, $selectedUser: [String!]) {
    users(
      first: $first
      page: $page
      project: {searchKey: UUID, keyword: $projectUUid}
      user: {searchKey: UUID, keyword: $selectedUser}
    ) {
      data {
        name
        uuid
        avatar
        invoices(
          first: 1
          orderBy: {column: CREATED_AT, order: DESC}
          project: {searchKey: UUID, keyword: $projectUUid}
          includeUnverifiedInvoices: true
        ) {
          data {
            updator {
              uuid
              name
            }
            to
            invoicedAt
            verifiedAt
            paidOn
            rejectedAt
            calculatedAmount
            __typename
          }
          __typename
        }
        activityAmount(
          project: {searchKey: UUID, keyword: $projectUUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        activityDuration(
          project: {searchKey: UUID, keyword: $projectUUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        manualDuration: recordsDuration(
          activityType: MANUAL
          project: {searchKey: UUID, keyword: $projectUUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        paymentSettings(project: {searchKey: UUID, keyword: $projectUUid}, first: 1) {
          data {
            effectiveFrom
            amount
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
  `,
  GET_USER_PAYMENT_SETTING_HISTORY: gql`
    query userPayment(
      $page: Int
      $first: Int
      $userId: String!
      $projectUUid: [String!]
    ) {
      user(uuid: $userId) {
        paymentSettings(
          project: { searchKey: UUID, keyword: $projectUUid }
          first: $first
          page: $page
        ) {
          data {
            amount
            paymentType
            effectiveFrom
            effectiveTo
          }
          total
        }
      }
    }
  `,

  GET_EXTRA_PAYMENT_CATEGORY: gql`
    query extraPaymentCategory {
      extraPaymentCategories {
        data {
          name
          displayName
        }
      }
    }
  `,

  GET_USER_INVOICE_HISTORY: gql`
    query getUserInvoiceHistory(
      $userUUID: String!
      $first: Int
      $page: Int
      $projectUUID: [String!]
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
    ) {
      user(uuid: $userUUID) {
        uuid
        invoices(
          project: { searchKey: UUID, keyword: $projectUUID }, 
          first: $first, 
          page: $page,
          between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
          orderBy: { column: CREATED_AT, order: DESC }
          includeUnverifiedInvoices: true
        ) {
          total
          data {
            updator {
              uuid
              name
            }
            invoicedAt
            invoiceId
            from
            to
            calculatedAmount
            paidAmount
            verifiedAt
            rejectedAt
            isPaid
            paidOn
            extraPayments {
              data {
                payment {
                  amount
                  record {
                    ... on ExtraPayment {
                      category {
                        name
                        displayName
                      }
                      remarks
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_USER_INVOICE_DATES: gql`
    query getUserInvoiceDates(
      $page: Int
      $first: Int
      $projectUid: [String!]
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
      $selectedUser: [String!]
    ) {
      users(
        first: $first
        page: $page
        project: { searchKey: UUID, keyword: $projectUid }
        user: { searchKey: UUID, keyword: $selectedUser }
      ) {
        total
        data {
          name
          uuid
          invoices(
            project: { searchKey: UUID, keyword: $projectUid }
            between: { startTime: $startTime, endTime: $endTime, timezone: $timezone }
            orderBy: { column: END_TIME, order: DESC },
            first: -1,
            includeUnverifiedInvoices: true
          ) {
            data {
              invoiceId
              invoicedAt
              verifiedAt
              from
              to
              paidOn
              paidAmount
            }
          }
        }
      }
    }
  `,

  GET_USER_TOTALS: gql`
    query getUserTotals(
      $first: Int
      $page: Int
      $projectUid: [String!]
      $startTime: DateTime!
      $endTime: DateTime!
      $timezone: String
      $selectedUser: [String!]
    ) {
    users(
      first: $first
      page: $page
      project: {searchKey: UUID, keyword: $projectUid}
      user: {searchKey: UUID, keyword: $selectedUser}
    ) {
      total
      data {
        uuid
        name
        avatar
        createdAt
        walletBalance
        activityAmount(
          project: {searchKey: UUID, keyword: $projectUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        activityDuration(
          project: {searchKey: UUID, keyword: $projectUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        manualDuration: recordsDuration(
          activityType: MANUAL
          project: {searchKey: UUID, keyword: $projectUid}
          between: {startTime: $startTime, endTime: $endTime, timezone: $timezone}
        )
        __typename
      }
      __typename
    }
  }
`,

  GET_COMPANY_BILLING_INFO: gql`
    query getCompanyBillingInfo($companyUid: String!) {
      company(uuid:$companyUid) {
        id
        managementInfo{
          settings {
            information {
              ... on PercentageBased {
                value
              }
              ... on MonthlyCharge {
                value
              }
            }
          }
        }
      }
    }
  `,
}
