<template>
  <div>
    <b-modal
      id="group-participants-modal"
      :title="$t('Manage Group Members')"
      no-close-on-backdrop
      @shown="$refs.groupParticipantsScroll.update()"
      @hidden="selectedUsers = []"
    >
      <b-list-group>
        <b-form-checkbox-group
          v-model="selectedUsers"
          name="group-users"
        >
          <perfect-scrollbar
            ref="groupParticipantsScroll"
            class="scroll-area"
            style="overflow-x: hidden;"
            :settings="perfectScrollbarSettings"
          >
            <b-list-group-item
              v-for="participant in allParticipants"
              :key="participant.uuid"
              class="p-0"
              :active="isActive(participant.uuid)"
              @click.prevent="toggleUserInGroup(participant.uuid)"
            >

              <b-form-checkbox
                :value="participant.uuid"
                class="w-100"
                :class="{ 'active': isActive(participant.uuid)}"
              >

                <div
                  class="d-flex justify-content-between p-1"
                  @mouseenter="hoveredParticipant = participant.uuid"
                  @mouseleave="hoveredParticipant = ''"
                >
                  <div class="d-flex">
                    <!-- Selector -->
                    <feather-icon
                      v-if="isAdmin && participant.uuid !== activeGroup.adminUid"
                      icon="CircleIcon"
                      size="24"
                      style="padding-top: 6px; cursor: pointer;"
                      class="mr-1"
                    />
                    <!-- User Details -->
                    <b-avatar
                      size="42"
                      :src="getAvatar(participant.avatar, 1)"
                      class="badge-minimal"
                      variant="primary"
                    />
                    <div class="ml-1">
                      <p class="font-weight-bold mb-0">
                        {{ participant.name }}
                        <span v-if="activeGroup.adminUid === participant.uuid">({{ $t('Group Admin') }})</span>
                      </p>
                      <span
                        v-if="participant.uuid === self.uuid"
                        class="text-muted"
                      >
                        {{ $t('You') }}
                      </span>
                      <span
                        v-else-if="participant.projectRoles"
                        class="text-muted text-capitalize"
                      >{{ participant.projectRoles.data[0].role.name }}
                      </span>
                    </div>
                  </div>

                  <!-- Buttons -->
                  <div class="d-flex align-items-center">
                    <div
                      v-if="!selectedUsers.length"
                      class="d-flex"
                    >
                      <!-- Privately Message -->
                      <b-button
                        v-if="participant.uuid !== self.uuid"
                        v-b-tooltip.hover="$t('chat.message-privately', { user: participant.name.split(' ')[0] })"
                        variant="link"
                        class="btn-icon rounded-circle"
                        :class="hoveredParticipant !== participant.uuid ? 'no-opacity' : ''"
                        @click.stop="$emit('message-privately', participant.roomUid ? participant.roomUid : participant.uuid)"
                      >
                        <i class="fas fa-comment-dots text-2" />
                      </b-button>

                      <!-- Leave Group -->
                      <b-button
                        v-else-if="activeGroup.adminUid !== participant.uuid"
                        v-b-tooltip.hover="$t('Leave Group')"
                        variant="link"
                        class="btn-icon rounded-circle"
                        :class="hoveredParticipant !== participant.uuid ? 'no-opacity' : ''"
                        @click.stop="$emit('leave-group')"
                      >
                        <i class="fas fa-sign-out-alt text-2 text-danger" />
                      </b-button>

                      <!-- Kick Participant -->
                      <b-button
                        v-if="activeGroup.adminUid === self.uuid && participant.uuid !== self.uuid && allParticipants.length>2"
                        v-b-tooltip.hover="$t('chat.remove-user', { user: participant.name.split(' ')[0] })"
                        variant="link"
                        class="btn-icon mr-1 rounded-circle"
                        :class="hoveredParticipant !== participant.uuid ? 'no-opacity' : ''"
                        @click.stop="$emit('kick-participant', participant.uuid)"
                      >
                        <i class="fas fa-minus-circle text-2 text-danger" />
                      </b-button>
                    </div>
                  </div>
                </div>
              </b-form-checkbox>
            </b-list-group-item>
          </perfect-scrollbar>
        </b-form-checkbox-group>
      </b-list-group>

      <template #modal-footer>
        <div
          class="d-flex w-100 justify-content-end"
        >
          <div
            v-if="selectedUsers.length"
            class="d-flex"
          >
            <b-button @click="selectedUsers = []">
              {{ $t('Clear Selection') }}
            </b-button>
            <b-button
              class="ml-2"
              variant="danger"
              :disabled="!canRemoveParticipants"
              @click="$emit('kick-participant', selectedUsers)"
            >
              <span v-if="canRemoveParticipants">
                {{ countSelected > 1 ? $t('actions.remove-members', { count: countSelected }) : $t('actions.remove-member') }}
              </span>
              <span
                v-else

                v-b-tooltip.hover="$t('chat.group-member-info')"
              ><feather-icon
                icon="AlertTriangleIcon"
                size="16"
                class="mr-1"
              />{{ countSelected > 1 ? $t('actions.remove-members', { count: countSelected }) : $t('actions.remove-member') }}</span>
            </b-button>

          </div>

          <div
            v-else
            class="justify-content-end"
          >
            <b-button
              variant="secondary"
              @click="$bvModal.hide('group-participants-modal')"
            >
              {{ $t('Close') }}
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click="$emit('add-new-members')"
            >
              {{ $t('actions.add-members') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BAvatar, BButton, BFormCheckboxGroup, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BAvatar,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    PerfectScrollbar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    activeGroup: {
      type: Object,
      required: true,
    },
    self: {
      type: Object,
      required: true,
    },
    groupAdmin: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      hoveredParticipant: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      selectedUsers: [],
    }
  },
  computed: {
    allParticipants() {
      const participants = JSON.parse(JSON.stringify(this.activeGroup.participants))
      let groupAdmin = participants.find(participant => participant?.uuid === this.activeGroup.adminUid)
      if (!groupAdmin) groupAdmin = this.self
      else participants.push(this.self)

      return [
        groupAdmin,
        ...participants.filter(participant => participant?.uuid !== groupAdmin.uuid),
      ]
    },
    isAdmin() {
      return this.activeGroup.adminUid === this.self.uuid
    },
    countSelected() {
      return this.selectedUsers.length
    },
    canRemoveParticipants() {
      return this.selectedUsers.length !== this.allParticipants.length - 1
    },
  },
  methods: {
    isActive(userId) {
      return this.selectedUsers.includes(userId)
    },
    toggleUserInGroup(userId) {
      if (!this.isAdmin) return
      if (this.activeGroup.adminUid === userId) return
      if (this.selectedUsers.includes(userId)) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(userId), 1)
      } else this.selectedUsers.push(userId)
    },
  },
}
</script>
