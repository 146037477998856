<template>
  <div>
    <b-button
      v-b-tooltip.hover="recorded ? $t('Delete') : recording ? $t('Release to Stop') : $t('Press and hold to record')"
      :variant="recording || recorded ? 'danger' : 'primary'"
      class="p-0 mr-1 rounded-circle action-btn"
      @mousedown="record()"
      @touchstart="record()"
      @touchend="stop()"
      @mouseup="stop()"
    >
      <feather-icon
        v-if="!recorded"
        :icon="recording ? 'SquareIcon' : 'MicIcon'"
      />
      <feather-icon
        v-else
        icon="TrashIcon"
      />
    </b-button>
  </div>
</template>

<script>
import {
  BButton, VBTooltip,
} from 'bootstrap-vue'
import jwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    send: {
      type: Boolean,
      default: () => false,
    },
    roomUid: {
      type: String,
      default: () => '',
    },
    isFloatingChat: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      audio: '',
      audioUrl: '',
      stream: null,
      mediaRecorder: null,
      recordingStartedAt: null,
      chunks: [],
      recording: false,
      recorded: false,
      timer: '00 sec',
      timerInterval: () => {},
      sec: 0,
      min: 0,
    }
  },
  watch: {
    recording(val) {
      if (val) {
        this.startTimer()
      } else this.stopTimer()
    },
    timer(val) {
      this.$emit('timer', val)
    },
    send: {
      handler(val) {
        if (val && this.audio) {
          this.sendFile()
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (this.isFloatingChat) this.timer = '00 \'\''
  },
  methods: {
    initialize() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            this.$emit('stream', stream)
            this.mediaRecorder = new MediaRecorder(stream)
            this.startRecording()
            this.mediaRecorder.onstop = () => {
              if (((new Date()).getTime()) >= this.recordingStartedAt + 1000) {
                const blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' })

                blob.name = `recording_${new Date()}.mp3`
                this.audio = blob

                this.audioUrl = window.URL.createObjectURL(blob)
                this.recorded = true
                this.chunks = []
                this.$emit('recorded', this.audioUrl)
              } else {
                this.chunks = []
                this.recording = false
                this.$emit('toggle-recording', false)
              }
              stream.getTracks()[0].stop()
            }
            this.mediaRecorder.ondataavailable = e => {
              this.chunks.push(e.data)
            }
          })
        // Error callback
          .catch(() => {
            this.showInfo('Please allow audio permission')
          })
      } else {
        this.showErrorMessage('Audio is not supported on your browser!')
      }
    },
    record() {
      if (!this.recording) {
        if (this.recorded) this.deleteAudio()
        else this.initialize()
      }
    },
    startRecording() {
      this.recordingStartedAt = (new Date()).getTime()
      this.mediaRecorder.start()
      this.recording = true
      this.$emit('toggle-recording', true)
    },
    stop() {
      this.recording = false
      if (this.mediaRecorder) this.mediaRecorder.stop()
    },
    deleteAudio() {
      this.clearAudio()
      this.$emit('toggle-recording', false)
      this.$emit('delete')
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.sec = Number(this.sec) + 1
        if (Number(this.sec) > 59) {
          this.sec = '00'
          this.min = Number(this.min) + 1
        }
        this.timer = `
          ${Number(this.min) > 0 ? `${this.formatTime(this.min, 'min', this.isFloatingChat ? '\'' : null)} ` : ''} ${this.formatTime(this.sec, 'sec', this.isFloatingChat ? '\'\'' : null)}
        `
      }, 1000)
    },
    formatTime(time, type, text = null) {
      if (String(time).length <= 1) return `0${this[type]} ${text ?? `${type}`}`
      return `${this[type]} ${text ?? type}`
    },
    stopTimer() {
      clearInterval(this.timerInterval)
    },
    sendFile() {
      const formData = new FormData()
      formData.append('file', this.audio)

      jwt.uploadChatResource(formData).then(async response => {
        const messageRequestId = await this.$chatService.sendAttachmentInRoom(this.roomUid, response.data)
        const data = {
          messageRequestId,
          ...response.data,
        }
        this.$emit('audio-sent', data)
        this.clearAudio()
      })
    },
    clearAudio() {
      this.audio = ''
      this.audioUrl = ''
      this.recorded = false
      this.timer = this.isFloatingChat ? '00\'\'' : '00 sec'
      this.sec = 0
      this.min = 0
    },
  },
}
</script>
