<template>
  <div>
    <div class="chats">
      <!-- Chat Body -->
      <div
        v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
        :key="`${msgGrp.sender}${String(index)}`"
        class="chat position-relative"
        :class="{'chat-left': msgGrp.sender !== self.uuid}"
      >
        <!-- User Avatar -->
        <div
          v-if="msgGrp.sender !== 'MEDIACALL' && msgGrp.sender !== 'GROUPACTION' && getMsgSender(msgGrp.sender)"
          class="chat-avatar"
        >
          <b-avatar
            size="36"
            class="avatar-border-2 box-shadow-1"
            variant="primary"
            :src="getAvatar(getMsgSender(msgGrp.sender).avatar)"
          />
          <small class="sender-name position-absolute">{{ getMsgSender(msgGrp.sender).name }}</small>
        </div>
        <!-- Chat Contents -->
        <div class="chat-body">
          <lazy-message-render
            v-for="(msgData, i) in msgGrp.messages"
            :key="msgData.messageUid"
            class="position-relative"
            :class="{ 'mb-1': i === msgGrp.messages.length -1 }"
            :render-on-idle="renderOnIdle"
            :unrender="true"
            :min-height="72"
            :unrender-delay="1000"
          >
            <div
              class="chat-content-wrapper"
              :class="(msgData.messageType === 'MEDIACALL' || msgData.messageType === 'GROUPACTION') ? 'justify-content-center' : ''"
              @mouseenter="hoveredMsg = msgData.messageUid"
              @mouseleave="() => { clearHoveredMsg(); showingReactOptions = false }"
            >
              <div :class="msgData.messageType === 'MEDIACALL' ? 'w-inherit' : 'w-100'">
                <!-- Messages and Reacts -->
                <div
                  class="message-render-wrapper"
                  :class="{
                    'mw-50 fmw-75': msgData.messageType === 'TEXT',
                    'mw-25 fmw-50': msgData.messageType === 'ATTACHMENT',
                    'w-100': msgData.messageType === 'GROUPACTION',
                  }"
                >
                  <message-render
                    :msg-data="msgData"
                    :self="self"
                    :sender-id="msgGrp.sender"
                    :contact-id="formattedChatData.contact.id"
                    :react-index="hoveredMsg"
                    @show-image="showImage"
                    @send-react="sendReact"
                    @showing-react-options="value => showingReactOptions = value"
                    @store-resource="resource => $emit('store-resource', { roomUid: formattedChatData.contact.roomUid, messageUid: msgData.messageUid, resource })"
                  />
                </div>
                <!-- Message Status Indicators -->
                <message-status-indicator
                  :self="self"
                  :msg-data="msgData"
                  :sender-id="msgGrp.sender"
                  :contact-id="formattedChatData.contact.id"
                  :hovered-msg="hoveredMsg"
                />
              </div>
            </div>
          </lazy-message-render>
        </div>
      </div>

      <div
        v-if="showScrollDownArrow"
        class="scroll-down-icon cursor-pointer position-fixed centralize"
        @click="$emit('scroll-down')"
      >
        <feather-icon
          icon="ArrowDownIcon"
          size="20"
        />
      </div>
    </div>

    <!-- Chat Image Preview -->
    <image-preview
      v-if="firstImageToShow"
      :chat-images="chatImages"
      :initial-image="firstImageToShow"
      :chat-type="chatType"
    />

    <!-- Peer is typing -->
    <div
      v-if="peerIsTyping"
      class="is-typing position-fixed"
    >
      <small>
        {{ $t('is-typing', { user: typingPeerName.split(' ')[0]}) }} {{ dots }}
      </small>
    </div>
  </div>
</template>

<script>
import {
  computed, getCurrentInstance, inject, ref, watch,
} from 'vue'
import { BAvatar, VBTooltip } from 'bootstrap-vue'
import { ChatEvents } from 'workzone-chat-sdk'
import { reacts as reactGroup } from '@/const/common'
import MessageRender from './components/MessageRender.vue'
import ImagePreview from './components/ImagePreview.vue'
import MessageStatusIndicator from './components/MessageStatusIndicator.vue'
import LazyMessageRender from './components/LazyMessageRender.vue'

export default {
  components: {
    BAvatar,
    MessageRender,
    ImagePreview,
    MessageStatusIndicator,
    LazyMessageRender,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    contacts: {
      type: Array,
      required: true,
    },
    chatData: {
      type: Object,
      required: true,
    },
    profileUserData: {
      type: Object,
      default: () => {},
    },
    self: {
      type: Object,
      required: true,
    },
    showScrollDownArrow: {
      type: Boolean,
      default: () => false,
    },
    chatType: {
      type: String,
      default: () => 'regular',
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy.$root
    const peerIsTyping = ref(false)
    const typingPeerName = ref('')
    const dots = ref('')
    let dotCount = 0
    let dotsInterval = () => {}
    const hoveredMsg = ref(null)
    const renderOnIdle = ref(false)

    const chatService = inject('$chatService')

    // update dots for ... animation
    const updateDots = () => {
      dots.value = '.'.repeat(dotCount)
      dotCount = (dotCount + 1) % 4
    }

    // set or clear interval by watching peerIsTyping
    watch(peerIsTyping, val => {
      if (val) dotsInterval = setInterval(updateDots, 400)
      else clearInterval(dotsInterval)
    })

    // set the isTyping flag to false if the chat contact changes
    watch(() => props.chatData.contact, () => {
      peerIsTyping.value = false
      renderOnIdle.value = true
      if (props.chatData.chat?.chat?.length) {
        setTimeout(() => { renderOnIdle.value = false }, 500)
      } else renderOnIdle.value = false
    }, { immediate: true })

    // Listen to typing event
    chatService.on(ChatEvents.RoomTypingEvent, data => {
      if (props.chatData.contact.type === 'group') {
        const typingPeer = props.chatData.contact.participants.find(participant => participant.uuid === data.sender)
        if (typingPeer) {
          peerIsTyping.value = data.typingEventType === 'on'
          typingPeerName.value = typingPeer.name
        }
      } else if (data.sender === props.chatData.contact.id) {
        peerIsTyping.value = data.typingEventType === 'on'
        typingPeerName.value = props.chatData.contact.name
      }
    })

    // Chat data mappings
    const formattedChatData = computed(() => {
      const {
        id, name, avatar, roomUid, participants, type,
      } = props.chatData.contact
      const contact = {
        id,
        name,
        avatar,
        roomUid,
        ...(participants
          ? {
            participants,
            type,
          }
          : []),
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].senderId : undefined
      let msgGroup = {
        sender: chatMessageSenderId,
        messages: [],
      }
      chatLog.forEach(({
        senderId, message, time, delivered, deliveredAt, seen, seenAt, seenBy, messageUid, messageType, reacts, attachment,
      }, index) => {
        if ((messageType !== 'MEDIACALL' && messageType !== 'GROUPACTION') && chatMessageSenderId === senderId) {
          msgGroup.messages.push({
            msg: message,
            time,
            delivered,
            deliveredAt,
            seen,
            seenAt,
            seenBy,
            messageUid,
            messageType,
            reacts,
            attachment,
          })
        } else {
          chatMessageSenderId = senderId
          if (messageType === 'MEDIACALL' || messageType === 'GROUPACTION') { chatMessageSenderId = null }
          if (msgGroup.messages.length) formattedChatLog.push(msgGroup)
          msgGroup = {
            sender: (messageType === 'MEDIACALL' || messageType === 'GROUPACTION') ? messageType : senderId,
            messages: [{
              msg: message,
              time,
              delivered,
              deliveredAt,
              seen,
              seenAt,
              seenBy,
              messageUid,
              messageType,
              reacts,
              attachment,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
      }
    })

    // send / unsend reacts
    const sendReact = (messageUid, msgReacts, react, type = 'send') => {
      if (type === 'send' || msgReacts.find(msgReact => msgReact.reactedBy === props.self.uuid)) {
        root.$emit('bv::hide::popover', 'reacts')
        chatService.broadcastMessageReactEvent(props.chatData.contact.roomUid, messageUid, react)
      }
    }

    const firstImageToShow = ref(null)

    const chatImages = computed(() => {
      const images = props.chatData.chat ? props.chatData.chat?.chat
        .filter(chat => chat.message.mime && chat.message.mime.includes('image')) : []
      return images
    })

    // show images preview
    const showImage = messageUid => {
      firstImageToShow.value = messageUid
      root.$nextTick(() => {
        root.$bvModal.show(`${props.chatType}-chat-images-preview-modal`)
      })
    }

    // get message sender data
    const getMsgSender = senderId => {
      if (senderId === props.self.uuid) {
        return {
          ...props.profileUserData,
          name: 'You',
        }
      }
      if (formattedChatData.value.contact && formattedChatData.value.contact.type === 'group') {
        return props.contacts.find(participant => participant.id === senderId)
      }
      return formattedChatData.value.contact
    }

    // react options on hover
    const showingReactOptions = ref(false)
    const clearHoveredMsg = () => {
      if (!showingReactOptions.value) hoveredMsg.value = null
    }

    return {
      formattedChatData,

      peerIsTyping,
      typingPeerName,
      dots,

      hoveredMsg,
      reactGroup,
      showingReactOptions,
      sendReact,
      clearHoveredMsg,

      firstImageToShow,
      chatImages,
      showImage,
      getMsgSender,

      renderOnIdle,
    }
  },
}
</script>

<style lang="scss" scoped>
.is-typing {
  bottom: 96px;
}
.floating-chat {
  .is-typing {
    bottom: 140px;
  }
}
.sidebar-chat {
  .is-typing {
    bottom: 72px;
  }
}
</style>
