<template>
  <div>
    <b-button
      id="smiley"
      v-b-tooltip.hover="$t('Emotes')"
      variant="primary"
      class="p-0 mr-1 rounded-circle action-btn"
      @click="showEmojiContainer = !showEmojiContainer"
    >
      <feather-icon icon="SmileIcon" />
    </b-button>

    <div
      v-if="showEmojiContainer"
      v-on-clickaway="() => { showEmojiContainer = false }"
      class="emotes-container"
    >
      <Picker
        set="apple"
        :show-preview="false"
        @select="value => $emit('insert', value)"
      />
    </div>
  </div>
</template>

<script>
import { Picker } from 'emoji-mart-vue'
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  components: {
    Picker,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [clickaway],
  data() {
    return {
      showEmojiContainer: false,
    }
  },
}
</script>
