<template>
  <b-modal
    id="add-to-group-modal"
    :title="`${newGroup? $t('Create New Group') : $t('Add Participants to Group')}`"
    no-close-on-backdrop
    :ok-title="newGroup ? $t('Create New Group') : $t('Add Participants')"
    :cancel-title="$t('Cancel')"
    :ok-disabled="!selectedUsers.length"
    @ok="inviteUserToGroup"
    @shown="$refs.addToGroupScroll.update()"
    @hidden="selectedUsers = []; searchTerm = ''"
  >
    <b-form-group>
      <b-input-group class="search-box input-group-merge w-100 round">
        <b-input-group-prepend is-text>
          <feather-icon
            icon="SearchIcon"
            class="text-muted"
          />
        </b-input-group-prepend>
        <b-form-input
          v-model="searchTerm"
          :placeholder="$t('Search Contacts')"
        />
        <b-input-group-append
          v-if="searchTerm"
          is-text
          class="cursor-pointer"
          @click="searchTerm = ''"
        >
          <feather-icon
            icon="XIcon"
            class="text-muted"
            size="18"
          />
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-list-group>
      <b-form-checkbox-group
        v-model="selectedUsers"
        name="group-users"
      >
        <perfect-scrollbar
          ref="addToGroupScroll"
          class="scroll-area"
          :settings="perfectScrollbarSettings"
        >
          <div v-if="filteredContacts.length">
            <b-list-group-item
              v-for="contact in filteredContacts"
              :key="contact.id"
              :active="isActive(contact.id)"
              @click.prevent="toggleUserInGroup(contact.id)"
            >
              <b-form-checkbox
                :value="contact.id"
                class="w-100"
                :class="{ 'active': isActive(contact.id)}"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex">
                    <b-avatar
                      size="42"
                      :src="getAvatar(contact.avatar, 1)"
                      class="badge-minimal"
                      variant="primary"
                    />
                    <div class="ml-1">
                      <p class="font-weight-bold mb-0">
                        {{ contact.name }}
                      </p>
                      <span
                        v-if="contact.projectRoles"
                        class="text-muted text-capitalize"
                      >{{ contact.projectRoles.data[0].role.name }}
                      </span>
                    </div>
                  </div>

                  <feather-icon
                    icon="CircleIcon"
                    size="20"
                  />
                </div>
              </b-form-checkbox>
            </b-list-group-item>
          </div>

          <div v-else>
            <b-list-group-item class="text-center">
              {{ $t('Sorry, no contacts found!') }}
            </b-list-group-item>
          </div>
        </perfect-scrollbar>
      </b-form-checkbox-group>
    </b-list-group>
  </b-modal>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BFormCheckboxGroup, BFormCheckbox, BAvatar, BFormGroup, BInputGroup, BInputGroupPrepend, BInputGroupAppend, BFormInput,
} from 'bootstrap-vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

export default {
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    BFormCheckboxGroup,
    BAvatar,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    PerfectScrollbar,
  },
  props: {
    contacts: {
      type: Array,
      default: () => {},
    },
    activeContact: {
      type: Object,
      required: true,
    },
    newGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
      },
      selectedUsers: [],
      searchTerm: '',
    }
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter(this.findUser)
        .filter(contact => contact.id !== this.activeContact.id && contact.type !== 'group')
        .filter(contact => !this.activeContact?.participants?.map(participant => participant?.uuid).includes(contact.id))
    },
  },
  methods: {
    toggleUserInGroup(userId) {
      if (this.selectedUsers.includes(userId)) {
        this.selectedUsers.splice(this.selectedUsers.indexOf(userId), 1)
      } else this.selectedUsers.push(userId)
    },
    isActive(userId) {
      return this.selectedUsers.includes(userId)
    },
    async inviteUserToGroup() {
      const roomUidSplit = this.activeContact.roomUid.split('::').slice(1, 3)
      const roomUid = roomUidSplit.join('::')
      await this.$chatService.inviteUser(roomUid, this.selectedUsers.join(','), this.newGroup)
      this.$emit('user-added')
    },
    findUser(user) {
      return user.name.toLowerCase().includes(this.searchTerm.toLowerCase())
    },
  },
}
</script>
