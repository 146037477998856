<template>
  <div
    v-if="$route.name !== 'chat'"
    class="floating-chat d-none d-md-block"
  >
    <draggable-button
      @trigger="buttonTriggered = true"
      @drag-stop="onDragStop"
    >
      <!-- New Message Indicator -->
      <b-badge
        v-show="activeRooms.length"
        ref="messageBadge"
        v-b-tooltip.hover="$t('New Message')"
        class="float-right"
        variant="primary"
        pill
      >
        {{ activeRooms.length }}
      </b-badge>

      <!-- Chat Button -->
      <div
        v-b-tooltip.hover="$t('View Chat')"
        class="floating-icon bg-primary d-flex justify-content-center align-items-center cursor-pointer"
        @click="onChatIconClicked"
      >
        <feather-icon
          :icon="showFloatingChat ? 'XIcon':'MessageSquareIcon'"
          size="30"
        />
      </div>

      <!-- Chat Component -->
      <div
        v-if="showFloatingChat"
        class="chat-box chat-application border border-2 shadow shadow-lg"
        :class="{ 'overflow-hidden': activeChat }"
        :style="[
          {'--chatbox-bottom': chatboxBottom, '--chatbox-right': chatboxRight},
          `${showingLeftSidebar ? 'overflow-y: auto' : 'overflow: hidden'}`
        ]"
      >
        <chat
          :is-floating-chat="true"
          :showing-floating-chat="showFloatingChat"
          @showing-left-sidebar="val => showingLeftSidebar = val"
          @active-chat="roomUid => handleActiveChat(roomUid)"
          @message-received="roomUid => pushActiveRoom(roomUid)"
          @clear-active-chat="() => activeChat = null"
        />
      </div>
    </draggable-button>
  </div>
</template>

<script>
import { BBadge, VBTooltip } from 'bootstrap-vue'
import EventBus from '@/event-bus'
import Chat from './Chat.vue'
import DraggableButton from '../common/components/DraggableButton.vue'

export default {
  components: {
    Chat,
    BBadge,
    DraggableButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showingLeftSidebar: true,
      activeRooms: [],
      activeChat: null,
      chatInitiated: false,
      chatboxBottom: '20px',
      chatboxRight: '60px',
      buttonTriggered: false,
    }
  },
  computed: {
    showFloatingChat: {
      get() {
        return this.$store.state.chat.showFloatingChat
      },
      set(value) {
        this.$store.commit('chat/UPDATE_SHOW_FLOATING_CHAT', { isFloating: value, activeChat: null })
      },
    },
    unseenMsgCounts() {
      return this.$store.state.chat.unseenMsgCounts
    },
  },
  watch: {
    showFloatingChat(val) {
      if (!val) {
        this.showingLeftSidebar = false
        this.activeChat = null
      }
    },
    unseenMsgCounts(val) {
      if (val.length) {
        this.activeRooms = val.map(msg => msg.roomUid)
      }
    },
  },
  mounted() {
    EventBus.$on('message-received', ({
      roomUid, sender, message, messageUid,
    }) => {
      if (this.$route.name !== 'chat') {
        if (this.activeChat !== roomUid) {
          this.pushActiveRoom(roomUid)
        }

        if (!this.chatInitiated) {
          this.setChat(roomUid, sender, message.payload, messageUid)
        }
      }
    })
    EventBus.$on('active-room', roomUid => this.pushActiveRoom(roomUid))
  },
  methods: {
    onClicked() {
      console.log('clicked')
    },
    pushActiveRoom(roomUid) {
      if (!this.activeRooms.includes(roomUid)) {
        this.activeRooms.push(roomUid)
        this.bounceAnimation()
      }
    },
    handleActiveChat(roomUid) {
      this.activeChat = roomUid
      if (this.activeRooms.includes(roomUid)) {
        this.activeRooms.splice(this.activeRooms.indexOf(roomUid), 1)
      }
    },
    setChat(roomUid, sender, message, messageUid) {
      if (this.$route.name !== 'chat') {
        const payload = {
          roomUid,
          sender,
          message,
          messageUid,
          type: 'received',
        }

        this.$store.dispatch('chat/setChat', payload)
        this.$nextTick(() => {
          this.bounceAnimation()
        })
      }
    },
    bounceAnimation() {
      if (this.$refs.messageBadge) {
        this.$refs.messageBadge.classList.add('message-badge')
        setTimeout(() => {
          this.$refs.messageBadge.classList.remove('message-badge')
        }, 1000)
      }
    },
    hideFloatingChat() {
      if (this.showFloatingChat) this.showFloatingChat = false
    },
    onDragStop({ left, top }) {
      if (left < -850) this.chatboxRight = '-360px'
      else if (left > -250) this.chatboxRight = '60px'

      if (top < -350) this.chatboxBottom = '-420px'
      else this.chatboxBottom = '20px'
    },
    onChatIconClicked() {
      if (this.buttonTriggered) {
        this.showFloatingChat = !this.showFloatingChat
        this.chatInitiated = true
      }
      this.buttonTriggered = false
    },
  },
}
</script>

<style lang="scss">
.floating-chat {
  position: fixed;
  bottom:60px;
  right:40px;
  z-index: 100;

  .floating-icon {
    width:54px;
    height:54px;
    color:white;
    border-radius:50%;
  }

  .chat-box {
    width:350px;
    height: 475px;
    position:absolute;
    bottom: var(--chatbox-bottom);
    right: var(--chatbox-right);
    border-radius:10px;
    overflow-x: hidden;
  }

  .draggable {
    position:fixed;
    bottom:60px;
    right:120px;
    width: 50px !important;
    height: 50px !important;
  }
}
.b-popover .arrow {
  z-index: -1;
}
</style>
