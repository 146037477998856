<template>
  <div
    :style="'height: inherit;'"
    :class="{'sidebar-chat': sidebarChat}"
  >
    <div
      class="body-content-overlay position-relative"
      :class="{
        show:
          shallShowUserProfileSidebar ||
          shallShowActiveChatContactSidebar ||
          mqShallShowLeftSidebar,
      }"
      @click="
        mqShallShowLeftSidebar =
          shallShowActiveChatContactSidebar =
          shallShowUserProfileSidebar =
          false
      "
    />

    <!-- Main Area -->
    <section
      class="chat-app-window position-relative"
      :style="'height: inherit'"
    >
      <!-- Start Chat Logo -->
      <div
        v-if="!activeChat.contact"
        class="start-chat-area"
      >
        <div class="chat-top-bar" />
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          class="sidebar-toggle start-chat-text"
          @click="startConversation"
        >
          {{ $t('Start Conversation') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <!-- Avatar & Name -->
            <div class="w-50 d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <div
                class="d-flex align-items-center cursor-pointer w-100"
                @click="
                  activeChat.contact.type !== 'group'
                    ? (shallShowActiveChatContactSidebar = true)
                    : ''
                "
              >
                <b-avatar
                  size="36"
                  :src="getAvatar(activeChat.contact.avatar)"
                  class="mr-1 badge-minimal"
                  badge
                  :badge-variant="
                    resolveAvatarBadgeVariant(activeChat.contact.status)
                  "
                >
                  <i
                    v-if="activeChat.contact.type === 'group'"
                    class="fas fa-user-friends"
                    :style="'font-size: 18px;'"
                  />
                </b-avatar>
                <div class="chat-title">
                  <div class="w-100 d-flex">
                    <h6
                      v-b-tooltip.hover="IS_MOBILE() ? '' : activeChat.contact.name"
                      class="mb-0 text-truncate"
                    >
                      {{ activeChat.contact.name }}
                    </h6>
                    <span
                      v-if="activeChat.contact.type==='group'"
                      class="options ml-1 small"
                    >
                      <b-link
                        class="cursor-pointer"
                        @click="!sidebarChat ? $bvModal.show('rename-group-modal') : ''"
                      >
                        <feather-icon
                          icon="Edit2Icon"
                          size="12"
                        />
                        <span v-if="!isFloatingChat && !sidebarChat && !IS_MOBILE()">{{ $t('Rename') }}</span>
                      </b-link>
                    </span>
                  </div>
                  <b-link
                    v-if="activeChat.contact.type === 'group'"
                    class="small muted mb-0"
                    @click="() => { !sidebarChat ? $bvModal.show('group-participants-modal') : '' }"
                  >
                    {{ activeChat.contact.participants.length + 1 }}
                    {{ $t('Participants') }}
                  </b-link>
                </div>
              </div>
            </div>

            <!-- Contact Actions -->
            <div class="actions d-flex align-items-center">
              <feather-icon
                v-if="!sidebarChat"
                v-b-tooltip.hover="$t('Audio Call')"
                icon="PhoneCallIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
                hover-color="#008000"
                @click="startCall('audio')"
              />
              <feather-icon
                v-if="!sidebarChat"
                v-b-tooltip.hover="$t('Video Call')"
                icon="VideoIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
                @click="startCall('video')"
              />
              <feather-icon
                v-if="!sidebarChat"
                v-b-tooltip.hover="$t('Add to Group')"
                v-b-modal.add-to-group-modal
                icon="UserPlusIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-1"
                @click="()=>{
                  createNewGroup = activeChat.contact.type === 'group' ? false : true
                }"
              />
              <feather-icon
                v-if="!isFloatingChat && !sidebarChat"
                v-b-tooltip.hover="$t('Minimize Chat')"
                icon="ArrowDownRightIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
                @click="miniMizeChat"
              />
              <feather-icon
                v-if="isFloatingChat || sidebarChat"
                v-b-tooltip.hover="$t('Close Chat')"
                icon="XIcon"
                size="17"
                class="cursor-pointer d-sm-block d-none mr-50"
                @click="onCloseChat"
              />
              <div
                v-if="!sidebarChat"
                class="dropdown"
              >
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="!isGroupChat"
                    @click="shallShowActiveChatContactSidebar = true"
                  >
                    {{ $t('View Contact') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else
                    v-b-modal.rename-group-modal
                  >
                    {{ $t('Rename Conversation') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="activeChat.contact.type === 'group'"
                    v-b-modal.add-to-group-modal
                    @click="
                      () => {
                        createNewGroup = true;
                      }
                    "
                  >
                    {{ $t('Create New Conversation') }}
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="isGroupChat && isGroupOwner"
                    variant="danger"
                    @click="showGroupActionModal('delete')"
                  >
                    {{ $t('Delete Conversation') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="isGroupChat && !isGroupOwner"
                    variant="danger"
                    @click="showGroupActionModal('leave')"
                  >
                    {{ $t('Leave Group') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <perfect-scrollbar
          ref="refChatLogPS"
          :options="perfectScrollbarSettings"
          class="scroll-area user-chats"
          @ps-scroll-y="onScroll"
        >
          <q-infinite-scroll
            :scroll-target="refChatLogPS"
            reverse
            @load="fetchMoreChat"
          >
            <div
              v-if="isLoadingChat"
              class="w-100 text-center"
            >
              <b-spinner
                type="grow"
                label="Loading"
              />
            </div>

            <chat-log
              v-if="contacts"
              :contacts="contacts"
              :chat-data="activeChat"
              :profile-user-data="profileUserDataMinimal"
              :show-scroll-down-arrow="showScrollDownArrow"
              :self="self"
              :chat-type="chatType"
              @scroll-down="scrollToBottomInChatLog"
              @store-resource="storeResource"
            />

            <!-- File / SnapShot Preview -->
            <files-preview
              v-if="chatFiles.length"
              :files="chatFiles"
              @remove-file="removeFile"
            />
          </q-infinite-scroll>
        </perfect-scrollbar>

        <!-- Message Input -->
        <b-form class="chat-app-form">
          <!-- Audio -->
          <div
            v-if="!showChatOptions"
            class="d-flex align-items-center"
          >
            <send-audio
              :send="sendAudio"
              :room-uid="activeChat.contact.roomUid"
              :is-floating-chat="isFloatingChat"
              @toggle-recording="value => { recordingAudio = value; if (!value) audio = '' }"
              @stream="value => audioStream = value"
              @timer="value => recordTimer = value"
              @recorded="value => audio = value"
              @audio-sent="pushFileToChat"
              @delete="clearAudio"
            />
          </div>

          <!-- Message Input -->
          <b-input-group lass="input-group-merge form-send-message mr-1">
            <!-- Record Visualization -->
            <div
              v-show="recordingAudio"
              class="w-100"
            >
              <div
                class="d-flex align-items-center"
                :class="isFloatingChat || IS_MOBILE() ? '' : 'mx-1'"
              >
                <audio
                  v-if="audio"
                  :src="audio"
                  :style="isFloatingChat ? 'width: 75%;' : 'width: 100%;'"
                  controls
                />
                <av-media
                  v-show="!audio"
                  type="frequ"
                  :media="audioStream"
                  :canv-width="isFloatingChat ? 140 : 640"
                  :canv-height="isFloatingChat ? 42 : 75"
                  :frequ-lnum="isFloatingChat ? 30 : 90"
                  line-color="darkorange"
                />
                <p
                  class="small mb-0"
                  :class="audio ? 'col-3 ' : 'col'"
                >
                  {{ recordTimer }}
                </p>
              </div>
            </div>

            <chat-input
              v-if="!recordingAudio && !showChatOptions"
              v-model="chatInputMessage"
              class="mr-1"
              :active-contact="activeChat.contact.id"
              @updateSnap="addFile"
              @onTyping="onTyping"
            />
          </b-input-group>

          <div v-if="(isFloatingChat || IS_MOBILE()) && !showChatOptions && !recordingAudio">
            <!-- More Button for Floating Chat -->
            <b-button
              v-b-tooltip.hover="$t('More Options')"
              class="p-0 action-btn mr-1 rounded-circle"
              variant="primary"
              @click="showChatOptions = true"
            >
              <feather-icon icon="MoreVerticalIcon" />
            </b-button>
          </div>

          <div :class="(!IS_MOBILE() && !isFloatingChat) || showChatOptions ? 'd-flex' : 'd-none'">
            <!-- Emotes -->
            <emotes
              v-if="!recordingAudio"
              @insert="emote => chatInputMessage += emote.native"
            />

            <!-- Stickers -->
            <stickers
              v-if="!recordingAudio"
              :active-contact="activeChat.contact"
              @sticker-sent="pushFileToChat"
            />

            <!-- Image Upload Button -->
            <chat-resource-input
              v-if="!recordingAudio"
              :room-uid="activeChat.contact.roomUid"
              :clear-form="clearFileInputForm"
              @file-sent="pushFileToChat"
              @preview-file="addFile"
              @form-cleared="clearFileInputForm = false"
            />

            <!-- Close Button for Chat Options -->
            <b-button
              v-if="(isFloatingChat || IS_MOBILE()) && !recordingAudio"
              v-b-tooltip.hover="$t('Close More Options')"
              class="p-0 action-btn mr-1 rounded-circle"
              variant="danger"
              @click="showChatOptions = false"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </div>

          <!-- Send -->
          <b-button
            v-b-tooltip.hover="$t('Send Message')"
            variant="primary"
            type="submit"
            :size="isFloatingChat || IS_MOBILE() ? 'sm' : ''"
            :disabled="recordingAudio && !audio"
            :class="isFloatingChat || IS_MOBILE() ? 'px-1' : ''"
            @click.prevent="sendMessage"
          >
            <feather-icon
              v-if="isFloatingChat || IS_MOBILE()"
              icon="SendIcon"
              size="16"
            />
            <span v-else>{{ $t('Send') }}</span>
          </b-button>
        </b-form>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="
        shallShowActiveChatContactSidebar
      "
      :contact="activeChat.contact || {}"
    />

    <!-- Sidebar -->
    <portal
      v-if="!isFloatingChat && !sidebarChat"
      to="content-renderer-sidebar-left"
    >
      <chat-left-sidebar
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="
          activeChat.contact ? activeChat.contact.roomUid : null
        "
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        :is-loading="isLoading"
        :scroll-to-user="scrollToUser"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="openChatOfContact"
      />
    </portal>

    <chat-left-sidebar
      v-else-if="mqShallShowLeftSidebar"
      class="position-absolute top-0"
      :chats-contacts="chatsContacts"
      :contacts="contacts"
      :active-chat-contact-id="
        activeChat.contact ? activeChat.contact.roomUid : null
      "
      :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
      :profile-user-data="profileUserData"
      :profile-user-minimal-data="profileUserDataMinimal"
      :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
      :is-loading="isLoading"
      :scroll-to-user="scrollToUser"
      @show-user-profile="showUserProfileSidebar"
      @open-chat="openChatOfContact"
    />

    <!-- Add User to Group Modal -->
    <add-to-group-modal
      v-if="activeChat.contact && contacts && !sidebarChat"
      :active-contact="activeChat.contact"
      :contacts="contacts"
      :new-group="createNewGroup"
      @user-added="$bvModal.hide('group-participants-modal')"
    />

    <!-- View Group Participants Modal -->
    <group-participants-modal
      v-if="!sidebarChat && activeChat.contact && activeChat.contact.type === 'group'"
      :active-group="activeChat.contact"
      :self="self"
      :group-admin="activeChat.contact.adminUid"
      @message-privately="onMessagePrivately"
      @leave-group="showGroupActionModal('leave')"
      @kick-participant="participant => showGroupActionModal('kick', participant)"
      @add-new-members="() => { createNewGroup = false; $bvModal.show('add-to-group-modal') }"
    />

    <!-- Rename Group Modal -->
    <rename-group-modal
      v-if="!sidebarChat && activeChat.contact && activeChat.contact.type === 'group'"
      :group="activeChat.contact"
    />

    <!-- Group Leave Modal -->
    <b-modal
      v-if="activeChat.contact && groupAction"
      id="group-action-modal"
      centered
      :title="groupActionModalInfo.title"
      :ok-title="groupActionModalInfo.buttonTitle"
      :cancel-title="$t('Cancel')"
      ok-variant="danger"
      no-close-on-backdrop
      @ok="groupModalAction"
    >
      <div class="d-flex align-items-center">
        <i
          class="fas text-3 text-danger"
          :class="groupActionModalInfo.icon"
        />
        <p class="mb-0 ml-1">
          {{ groupActionModalInfo.message }}
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import {
  ref,
  inject,
  watch,
  computed,
  onUnmounted,
  nextTick,
  onMounted,
  getCurrentInstance,
} from 'vue'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BButton,
  BLink,
  VBTooltip,
  BModal,
  BSpinner,
} from 'bootstrap-vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import EventBus from '@/event-bus'
import { getUserData } from '@/auth/utils'
import { ChatEvents } from 'workzone-chat-sdk'
import outgoing from '@/chat/components/audio/outgoing.mp3'
import moment from 'moment'
import jwt from '@/auth/jwt/useJwt'
import QInfiniteScroll from '@/infinite-scroll/index'
import _ from 'lodash'
import ChatInput from './components/Input/ChatInput.vue'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import ChatResourceInput from './components/Input/ChatResourceInput.vue'
import Emotes from './components/Input/Emotes.vue'
import SendAudio from './components/Input/SendAudio.vue'
import FilesPreview from './components/Input/FilesPreview.vue'
import Stickers from './components/Stickers/Index.vue'
import AddToGroupModal from './components/Group/AddToGroupModal.vue'
import GroupParticipantsModal from './components/Group/GroupParticipantsModal.vue'
import RenameGroupModal from './components/Group/RenameGroupModal.vue'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

export default {
  components: {
    // BSV
    BAvatar,
    BModal,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BButton,
    BLink,
    BSpinner,

    // 3rd Party
    PerfectScrollbar,
    QInfiniteScroll,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
    ChatResourceInput,
    Emotes,
    SendAudio,
    FilesPreview,
    Stickers,
    AddToGroupModal,
    RenameGroupModal,
    GroupParticipantsModal,
    ChatInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isFloatingChat: {
      type: Boolean,
      default: () => false,
    },
    sidebarChat: {
      type: Boolean,
      default: () => false,
    },
    currentActiveChat: {
      type: Object,
      default: () => null,
    },
  },

  setup(props) {
    const root = getCurrentInstance().proxy.$root

    const chatService = inject('$chatService')

    const isLoading = ref(false)
    const createNewGroup = ref(false)

    const {
      resolveAvatarBadgeVariant, mapChat, createGroupName, GET_CALL_ERRORS, GET_FILE_ICON,
    } = useChat()
    const self = getUserData()

    const activeChat = ref({})
    const chatsContacts = ref([])
    const contacts = ref([])

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToUser = ref('')

    const showingFloatingChat = computed(() => store.state.chat.showFloatingChat)
    const showChatOptions = ref(false)
    const noMoreChatToFetch = ref(false)

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    const noSidebarOrOnlySidebar = computed(() => !props.sidebarChat || (props.sidebarChat && root.$route.name !== 'chat'))

    watch(() => activeChat.value.contact, (val, oldVal) => {
      noMoreChatToFetch.value = false
      if (oldVal && oldVal.chat?.chat?.length > 20) {
        store.dispatch('chat/trimChat', oldVal.roomUid)
      }
    })

    // ------------------------------------------------
    // Helper Functions
    // ------------------------------------------------

    const scrollToBottomInChatLog = () => {
      if (refChatLogPS.value) {
        const scrollEl = refChatLogPS.value.$el ?? refChatLogPS.value
        scrollEl.scrollTop = scrollEl.scrollHeight
      }
    }

    // Shift chat to the top of the list
    const shiftChatToTop = chatContact => {
      if (chatsContacts.value.length > 1) {
        const index = chatsContacts.value.indexOf(chatContact)
        chatsContacts.value.splice(index, 1)
        chatsContacts.value.unshift(chatContact)
      }
    }

    // Get chatchatContact by roomUid
    const getChatContactByRoomUid = roomUid => (chatsContacts.value.find(contact => contact.roomUid === roomUid))

    // Get chatContact by id
    const getChatContactById = id => (chatsContacts.value.find(contact => contact.id === id))

    // Get contact by roomUid
    const getContactByRoomUid = roomUid => (contacts.value.find(contact => contact.roomUid === roomUid))

    // Get contact by id
    const getContactById = id => (contacts.value.find(contact => contact.id === id))

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (
        store.state.app.windowWidth < $themeBreakpoints.lg
        || props.isFloatingChat
      ) {
        mqShallShowLeftSidebar.value = true
      }
    }

    onMounted(() => {
      if (root.IS_MOBILE()) startConversation()
      setTimeout(() => scrollToBottomInChatLog(), 300)
    })

    // Pushes the message into the chat log
    const pushIntoChat = (roomUid, chat, contact, message, type = 'sent') => {
      // Initial Chat
      if (chat !== undefined) {
        if (
          activeChat.value.contact
          && activeChat.value.contact.roomUid === roomUid
        ) { activeChat.value = { chat, contact } }
        chatsContacts.value.unshift({
          ...contact,
          chat: {
            id: chat.id,
            lastMessage: {
              ...message,
              time: new Date(),
            },
            chat: chat.chat,
            unseenMsgs: 0,
          },
        })
        // Chat already exists
      } else {
        const chatContact = getChatContactByRoomUid(roomUid)
        shiftChatToTop(chatContact)

        // Message received room is active
        if (
          activeChat.value.contact
          && activeChat.value.contact.roomUid === roomUid
        ) {
          activeChat.value.chat.chat.push(message)

          // Message received room is not active
        } else chatContact.chat.chat.push(message)
      }

      if (type === 'received') {
        // increase the unseen msg count by 1
        if (
          !activeChat.value.contact
          || (activeChat.value.contact
            && activeChat.value.contact.roomUid !== roomUid)
        ) {
          const chatContact = getChatContactByRoomUid(roomUid)
          chatContact.chat.unseenMsgs += 1
        } else {
          // send message read event
          chatService.broadcastMessageSeenEvent(roomUid, message.messageUid)
        }
      }

      setTimeout(() => scrollToBottomInChatLog(), 200)
    }

    const pushFileToChat = data => {
      showChatOptions.value = false
      const { messageRequestId, ...message } = data
      const payload = {
        roomUid: activeChat.value.contact.roomUid,
        message,
        messageType: data.type ?? 'ATTACHMENT',
        messageRequestId,
        senderId: self.uuid,
        contactId: activeChat.value.contact.id,
      }

      store.dispatch('chat/sendMessage', payload).then(response => {
        const { roomUid, newMessageData, chat } = response.data
        // eslint-disable-next-line no-use-before-define
        pushIntoChat(
          roomUid,
          chat,
          activeChat.value.contact,
          newMessageData,
          payload.type,
        )

        const contact = getChatContactById(activeChat.value.contact.id)
        contact.chat.lastMessage = newMessageData
        contact.chat.unseenMsgs = 0
      })
      // eslint-disable-next-line no-use-before-define
      clearAudio()
      setTimeout(() => scrollToBottomInChatLog(), 200)
    }

    const chatType = computed(() => {
      if (props.sidebarChat) return 'sidebar'
      if (props.isFloatingChat) return 'floating'
      return 'regular'
    })

    // ------------------------------------------------
    // For Sidebar Chat
    // ------------------------------------------------
    watch(() => props.currentActiveChat, val => {
      if (val && props.sidebarChat) {
        activeChat.value = val
      }
    }, { immediate: true })

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const fetchChatAndContacts = () => {
      isLoading.value = true
      store
        .dispatch('chat/fetchChatsAndContacts')
        .then(response => {
          if (
            chatsContacts.value.find(
              chatContact => !response.data.chatsContacts
                .map(ch => ch.id)
                .includes(chatContact.id),
            )
          ) {
            chatsContacts.value.push(...response.data.chatsContacts)
          } else {
            chatsContacts.value = response.data.chatsContacts.sort((a, b) => {
              const date1 = moment(a.chat.lastMessage?.time)
              const date2 = moment(b.chat.lastMessage?.time)
              return date2.diff(date1, 'seconds')
            })
          }
          contacts.value = response.data.contacts
          // eslint-disable-next-line no-use-before-define
          profileUserDataMinimal.value = response.data.profileUser

          if (activeChat.value.contact) {
            const active = getChatContactByRoomUid(activeChat.value.contact.roomUid)
            if (active) activeChat.value.contact.adminUid = active.adminUid
          }
        })
        .finally(() => {
          if (contacts.value.length) isLoading.value = false
          else {
            setTimeout(() => {
              isLoading.value = false
            }, 1200)
          }
        })
    }

    fetchChatAndContacts()

    // if the store is loaded after the initial fetch is made
    // or, if the room is newly resolved, fetch the chat and contacts from the store
    const projectUsers = computed(() => store.state.project.projectUsers)
    const storedRooms = computed(() => store.state.chat.rooms)
    watch(
      projectUsers,
      val => {
        if (val) fetchChatAndContacts()
      },
      { deep: true },
    )

    watch(storedRooms, val => {
      if (!val.length) fetchChatAndContacts()
    })

    EventBus.$off('room-joined')
    EventBus.$on('room-joined', _.debounce(() => {
      fetchChatAndContacts()
    }, 1000))

    // set roomUid of the activeChat when the room is resolved
    EventBus.$off('room-resolved')
    EventBus.$on('room-resolved', data => {
      if (data.isGroupChat) {
        store.dispatch('chat/fetchChatsAndContacts').then(response => {
          const contact = response.data.contacts.find(
            user => user.roomUid === data.roomUid,
          )

          // eslint-disable-next-line no-use-before-define
          openChatOfContact(contact.id)

          setTimeout(() => {
            scrollToUser.value = contact.id
            setTimeout(() => {
              scrollToUser.value = null
            }, 300)
          }, 300)
        })
      } else activeChat.value.contact.roomUid = data.roomUid
    })

    // ------------------------------------------------
    // Message Delivered / Received Event
    // ------------------------------------------------
    // Listen to message sent event and set the messageUid
    EventBus.$off('message-sent')
    EventBus.$on('message-sent', data => {
      const chatContact = getChatContactByRoomUid(data.roomUid)
      const chat = chatContact.chat.chat.find(
        ch => ch.messageRequestId === data.message.messageRequestId,
      )
      chat.messageUid = data.messageUid
    })

    // Listen to message delivered event, and set the message status to delivered
    EventBus.$off('message-delivered')
    EventBus.$on('message-delivered', data => {
      let chat = null
      const chatContact = getChatContactByRoomUid(data.roomUid)
      if (!chatContact) {
        return
      }
      if (data.messageUid) {
        chat = chatContact.chat.chat.find(
          ch => ch.messageUid === data.messageUid,
        )
      } else {
        chat = chatContact.chat.chat.find(
          ch => ch.messageRequestId === data.message.messageRequestId,
        )
      }
      if (!chat) return
      chat.delivered = true
      chat.deliveredAt = new Date()
    })

    // Message Received Event
    EventBus.$on('message-received', data => {
      let chatContact = getChatContactByRoomUid(data.roomUid)

      // For Reacts
      if (data.type === 'react') {
        const isActiveChat = activeChat.value?.contact?.roomUid === data.roomUid
        let message
        if (isActiveChat) {
          message = activeChat.value.chat.chat.find(
            chat => chat.messageUid === data.messageUid,
          )
        } else {
          message = chatContact.chat.chat.find(
            chat => chat.messageUid === data.messageUid,
          )
        }

        // Push new react or update previous react only if there is the message
        if (message) {
          const previousReact = message.reacts?.find(
            react => react.reactedBy === data.peerUid,
          )
          if (data.reacted) {
            if (previousReact) {
              previousReact.reaction = data.reaction
            } else {
              message.reacts.push({
                reactedBy: data.peerUid,
                reactedAt: new Date(),
                reaction: data.reaction,
              })
            }
          } else {
            message.reacts.splice(message.reacts.indexOf(previousReact), 1)
          }
        }
      } else {
        // For Other Messages
        if (chatContact && !chatContact.chat.chat.find(chat => chat.messageUid === data.messageUid)) {
          chatContact.chat.lastMessage = {
            message: data.message.payload,
            messageType: data.message.type,
            messageUid: data.messageUid,
            time: new Date(),
          }
        } else chatContact = getContactByRoomUid(data.roomUid) ?? getContactById(data.sender)

        if (props.isFloatingChat && !showingFloatingChat.value) {
          store.dispatch('chat/increaseUnseenMsg', data.roomUid)
          chatContact.chat.unseenMsgs += 1
          EventBus.$emit('active-room', data.roomUid)
        }

        // push the chat message into chat log for receiver
        const payload = {
          roomUid: data.roomUid,
          message: data.message.payload,
          messageType: data.message.type,
          senderId: data.sender,
          contactId: self.uuid,
          type: 'received',
          messageRequestId: data.message.messageRequestId,
          messageUid: data.messageUid,
        }

        store.dispatch('chat/sendMessage', payload).then(response => {
          const { roomUid, newMessageData, chat } = response.data
          // eslint-disable-next-line no-use-before-define
          pushIntoChat(
            roomUid,
            chat,
            chatContact,
            newMessageData,
            payload.type,
          )
          if (data.message.type !== 'GROUPACTION') {
            setTimeout(() => {
              scrollToUser.value = roomUid
              setTimeout(() => {
                scrollToUser.value = null
              }, 300)
            }, 300)
          }
        })
      }

      setTimeout(() => scrollToBottomInChatLog(), 50)
    })

    // ------------------------------------------------
    // Message Read Event
    // ------------------------------------------------
    const markTextRead = data => {
      const chatContact = getChatContactByRoomUid(data.roomUid)
      if (chatContact) {
        const chat = chatContact.chat.chat.find(
          ch => ch.messageUid === data.messageUid,
        )
        if (chat) {
          chat.seen = true
          chat.seenBy.push(
            getContactById(data.sender)?.name,
          )
          chat.seenAt = new Date()
        }
      }
    }

    chatService.on(ChatEvents.RoomTextMessageMarkedReadEvent, markTextRead)

    // ------------------------------------------------
    // Audio Message
    // ------------------------------------------------
    const recordingAudio = ref(false)
    const audioStream = ref(null)
    const recordTimer = ref(props.isFloatingChat ? "00''" : '00 sec')
    const audio = ref('')
    const sendAudio = ref(false)

    const clearAudio = () => {
      if (audio.value) {
        audio.value = ''
        sendAudio.value = false
        recordingAudio.value = false
        recordTimer.value = props.isFloatingChat ? "00''" : '00 sec'
      }
    }

    // ------------------------------------------------
    // SnapShot Images and Files
    // ------------------------------------------------
    const chatFiles = ref([])
    const addFile = (fileData, base64) => {
      let data
      if (fileData.type === 'image') {
        const { file, url, type } = fileData
        data = {
          url,
          type,
          file,
        }
      } else if (fileData.type.includes('image')) {
        data = {
          url: base64,
          type: 'image',
          file: fileData,
        }
      } else {
        const { file, type } = fileData
        data = {
          icon: GET_FILE_ICON(type),
          file,
        }
      }
      chatFiles.value.push(data)
    }

    const clearFileInputForm = ref(false)
    const removeFile = index => {
      chatFiles.value.splice(index, 1)
      clearFileInputForm.value = true
    }

    const sendFile = file => {
      const formData = new FormData()
      formData.append('file', file)

      jwt
        .uploadChatResource(formData)
        .then(async response => {
          const messageRequestId = await chatService.sendAttachmentInRoom(
            activeChat.value.contact.roomUid,
            response.data,
          )
          const data = {
            messageRequestId,
            ...response.data,
          }
          pushFileToChat(data)
          const index = chatFiles.value.indexOf(
            fileData => fileData.file === file,
          )
          chatFiles.value.splice(index, 1)
        })
        .finally(() => {
          clearFileInputForm.value = true
        })
    }

    const sendFiles = () => {
      chatFiles.value.forEach(fileData => {
        sendFile(fileData.file)
      })
    }

    const storeResource = ({ roomUid, messageUid, resource }) => {
      const chatContact = getChatContactByRoomUid(roomUid)
      const chat = chatContact.chat.chat.find(
        message => message.messageUid === messageUid,
      )
      chat.attachment = resource
    }

    // ------------------------------------------------
    // Chat
    // ------------------------------------------------
    const chatInputMessage = ref('')

    const openChatOfContact = async userId => {
      const contact = getChatContactById(userId)
      const userIdSplit = userId.split('::')
      const isGroup = userIdSplit[userIdSplit.length - 1] === '{G}'

      if (!contact) {
        if (!isGroup) await chatService.inviteUser(userId, userId)
      }

      // Reset send message input value
      chatInputMessage.value = ''
      let roomUid = ''
      if (isGroup) roomUid = userId
      else roomUid = getContactById(userId)?.roomUid

      const payload = {
        userId,
        roomUid,
      }

      store.dispatch('chat/getChats', payload).then(response => {
        activeChat.value = { ...response.data }
        if (contact && contact.chat.chat) {
          contact.chat.chat.forEach(chat => {
            if (
              chat.senderId !== self.uuid
              && (!chat.views || !chat.views.find(view => view.viewedBy === self.uuid))
              && chat.messageType !== 'MEDIACALL' && chat.messageType !== 'GROUPACTION'
            ) {
              chatService.broadcastMessageSeenEvent(roomUid, chat.messageUid)
            }
          })

          // Set unseenMsgs to 0
          contact.chat.unseenMsgs = 0
        }

        // Scroll to bottom
        setTimeout(() => scrollToBottomInChatLog(), 500)
      })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
      shallShowActiveChatContactSidebar.value = false

      if (props.isFloatingChat) {
        root.$emit('active-chat', roomUid)
      }
    }

    const sendMessage = async () => {
      showChatOptions.value = false
      if (audio.value) {
        sendAudio.value = true
        return
      }
      if (chatFiles.value.length) sendFiles()
      // eslint-disable-next-line no-use-before-define
      if (!chatInputMessage.value) return

      const messageRequestId = await chatService.sendTextMessageInRoom(
        activeChat.value.contact.roomUid,
        chatInputMessage.value,
      )

      const payload = {
        roomUid: activeChat.value.contact.roomUid,
        message: chatInputMessage.value,
        messageType: 'TEXT',
        senderId: self.uuid,
        contactId: activeChat.value.contact.id,
        messageRequestId,
      }

      store.dispatch('chat/sendMessage', payload).then(response => {
        const { roomUid, newMessageData, chat } = response.data
        pushIntoChat(
          roomUid,
          chat,
          activeChat.value.contact,
          newMessageData,
          'sent',
        )

        // Reset send message input value
        chatInputMessage.value = ''

        // Set Last Message for active contact
        const contact = getChatContactById(activeChat.value.contact.id)
        contact.chat.lastMessage = newMessageData
        contact.chat.unseenMsgs = 0
        // Scroll to bottom
        setTimeout(() => scrollToBottomInChatLog(), 70)
      })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
      wheelPropagation: false,
    }

    // ------------------------------------------------
    // Call
    // ------------------------------------------------
    let ring = null
    let ringInterval = () => {}
    let callTimeout = () => {}

    // Stop call ringing
    const stopRinging = () => {
      if (ring) {
        ring.pause()
        ring.currentTime = 0
        clearInterval(ringInterval)
        clearTimeout(callTimeout)
      }
    }

    // Disconnect call
    const callDisconnected = () => {
      stopRinging()
      // EventBus.$emit('call-ended')

      // chatService.endMediaCall(data.room)

      // Need to Stop
      chatService.off(ChatEvents.CallDisconnected, callDisconnected)
    }

    // Start Call
    const startCall = async callType => {
      store.commit('chat/UPDATE_SHOW_FLOATING_CHAT', {
        isFloating: props.isFloatingChat,
        activeChat: activeChat.value,
      })
      chatService
        .startCallInRoom(activeChat.value.contact.roomUid, callType)
        .then(client => {
          ring = new Audio(outgoing)
          ring.play()
          ringInterval = setInterval(() => {
            ring.play()
          }, 3000)

          EventBus.$emit('outgoing', activeChat.value, client)

          // End the call after a minute if not answered
          callTimeout = setTimeout(() => {
            stopRinging()
            chatService.endMediaCall(activeChat.value.contact.roomUid)
            EventBus.$emit('call-ended')
            const message = GET_CALL_ERRORS(
              'E_NO_ANSWER',
              activeChat.value.contact.name,
              activeChat.value.contact.type,
            )
            root.showDangerMessage({ title: 'No Answer', message })
          }, 1000 * 60)
        })
        .catch(err => {
          const message = GET_CALL_ERRORS(
            err.err,
            activeChat.value.contact.name,
            activeChat.value.contact.type,
          )
          root.showDangerMessage({ title: 'Error', message })
        })
    }

    // if the call is accepted, clear the call timeout
    EventBus.$on('user-on-call', () => clearTimeout(callTimeout))

    // Stop ringing when the call is accepted by the peer
    chatService.on(ChatEvents.CallEstablished, () => stopRinging())

    // On Call Disconnection
    chatService.on(ChatEvents.CallDisconnected, data => callDisconnected(data))

    // Stop outgoing ringtone when the call is terminated by the caller
    EventBus.$on('call-ended', () => stopRinging())

    // ------------------------------------------------
    // User Profile Sidebar
    // ------------------------------------------------
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('chat/getProfileUser').then(response => {
        profileUserData.value = response
        shallShowUserProfileSidebar.value = true
      })
    }

    // ------------------------------------------------
    // Floating Chat
    // ------------------------------------------------
    const router = root.$router

    // show left sidebar on startup if it's floating chat
    if (props.isFloatingChat) {
      mqShallShowLeftSidebar.value = true
    }

    // minimize chat to floating chat
    const miniMizeChat = () => {
      store.commit('chat/UPDATE_SHOW_FLOATING_CHAT', {
        isFloating: true,
        activeChat: activeChat.value,
      })
      router.push('/home')
    }

    // Keep the activeChat active in the floating chat
    const storedActiveChat = computed(() => store.state.chat.activeChat)
    watch(storedActiveChat, val => {
      if (storedActiveChat && props.isFloatingChat) {
        activeChat.value = val
      }
    })

    // Emit showing left sidebar to floating chat to handle scrolling behaviour
    const showingLeftSidebar = val => {
      if (props.isFloatingChat) root.$emit('showing-left-sidebar', val)
    }

    watch(mqShallShowLeftSidebar, val => showingLeftSidebar(val))
    watch(
      () => showingFloatingChat.value,
      val => {
        if (props.isFloatingChat && !val) activeChat.value = {}
      },
    )

    const onCloseChat = () => {
      if (props.isFloatingChat) {
        mqShallShowLeftSidebar.value = true
        activeChat.value = {}
        root.$emit('clear-active-chat')
      } else root.$emit('hide-sidebar-chat')
    }

    // ------------------------------------------------
    // Typing Event
    // ------------------------------------------------
    const isTyping = ref(false)
    let typingTimeout = () => {}

    watch(isTyping, (val, oldVal) => {
      if (val !== oldVal) {
        chatService.broadCastTypingEvent(
          activeChat.value.contact.roomUid,
          val ? 'on' : 'off',
        )
      }
    })

    const onTyping = () => {
      isTyping.value = true
      // only set timeout for the last key press
      clearTimeout(typingTimeout)
      typingTimeout = setTimeout(() => {
        isTyping.value = false
      }, 1000)
    }

    const showScrollDownArrow = ref(false)

    // ------------------------------------------------
    // Fetch More Chat
    // ------------------------------------------------
    const isLoadingChat = ref(false)
    const fetchChatHistory = data => {
      chatService.off(ChatEvents.MessageHistoryFetched, fetchChatHistory)
      const oldMessages = mapChat(data, contacts, self)

      setTimeout(() => {
        if (oldMessages.length) {
          activeChat.value.chat.chat.splice(0, 0, ...oldMessages)

          // scroll down after adding old messages
          if (refChatLogPS.value) refChatLogPS.value.$el.scrollTop = 2300

          // if less than 20 messages are fetched, there are no more chat to fetch
          if (oldMessages.length < 20) noMoreChatToFetch.value = true
        } else {
          noMoreChatToFetch.value = true
          refChatLogPS.value.$el.scrollTop = 0
        }
        nextTick(() => { isLoadingChat.value = false })
      }, 100)
    }

    const onScroll = () => {
      const container = refChatLogPS.value.$el
      showScrollDownArrow.value = !(
        container.scrollHeight - container.clientHeight
        <= container.scrollTop + 200
      )
    }

    const fetchMoreChat = (index, done) => {
      if (!isLoadingChat.value && !noMoreChatToFetch.value && activeChat.value.chat?.chat?.length > 9) {
        isLoadingChat.value = true
        if (activeChat.value.chat && activeChat.value.chat.chat.length) {
          chatService.on(ChatEvents.MessageHistoryFetched, fetchChatHistory)
          chatService.fetchMessageHistory(
            activeChat.value.contact.roomUid,
            activeChat.value.chat.chat[0].messageUid,
          )
        } else isLoadingChat.value = false
      }
      done()
    }

    // ------------------------------------------------
    // Group Chats
    // ------------------------------------------------
    const groupAction = ref()
    const participantsToBeKicked = ref(null)
    const participantUuidsToBeKicked = ref(null)

    const isGroupChat = computed(
      () => activeChat.value.contact?.type === 'group',
    )
    const isGroupOwner = computed(
      () => activeChat.value.contact?.adminUid === self.uuid,
    )
    const groupActionModalInfo = computed(() => {
      switch (groupAction.value) {
        case 'leave':
          return {
            title: `${root.$t('chat.leave-group')} - ${activeChat.value.contact.name}`,
            message: `${root.$t('chat.leave-group-info')} - ${activeChat.value.contact.name}?`,
            buttonTitle: root.$t('Leave'),
            icon: 'fa-sign-out-alt',
          }
        case 'kick':
          return {
            title: root.$t('chat.remove-user', { user: participantsToBeKicked.value }),
            message: root.$t('chat.remove-user-info', { user: participantsToBeKicked.value }),
            buttonTitle: root.$t('Remove'),
            icon: 'fa-minus-circle',
          }
        case 'delete':
        default:
          return {
            title: root.$t('chat.delete-conversation'),
            message: root.$t('chat.delete-conversation-info'),
            buttonTitle: root.$t('Delete'),
            icon: 'fa-trash-alt',
          }
      }
    })

    // Private Message To Group Member
    const onMessagePrivately = roomUid => {
      const contactUser = getContactByRoomUid(roomUid) ?? getContactById(roomUid)
      openChatOfContact(contactUser.id)
      scrollToUser.value = contactUser.id
      setTimeout(() => {
        scrollToUser.value = null
        scrollToBottomInChatLog()
      }, 300)
    }

    // Leave Group Chat
    const leaveGroupChat = () => {
      const { roomUid } = activeChat.value.contact
      chatService.leaveRoom(roomUid)
      root.$nextTick(() => {
        let chatContact = getChatContactByRoomUid(roomUid)
        if (chatContact) {
          chatsContacts.value.splice(
            chatsContacts.value.indexOf(chatContact),
            1,
          )
        } else {
          chatContact = getContactByRoomUid(roomUid)
          contacts.value.splice(contacts.value.indexOf(chatContact), 1)
        }
        activeChat.value = {}
      })
    }

    // Delete Group Chat
    const deleteConversation = () => {
      const { roomUid } = activeChat.value.contact
      chatService.deleteRoom(roomUid)
    }

    // Kick Participant
    const kickParticipant = () => {
      chatService.kickUsersFromRoom(
        activeChat.value.contact.roomUid,
        participantUuidsToBeKicked.value,
      )
      root.$nextTick(() => {
        root.$bvModal.hide('group-participants-modal')
      })
    }

    // Group Modal Actions
    const groupModalAction = () => {
      switch (groupAction.value) {
        case 'leave':
          leaveGroupChat()
          break
        case 'kick':
          kickParticipant()
          break
        case 'delete':
          deleteConversation()
          break
        default:
      }
    }

    // Show Group Action Modal
    const showGroupActionModal = (action, participants = null) => {
      groupAction.value = action
      let participantsArr
      if (!Array.isArray(participants)) participantsArr = [participants]
      else participantsArr = participants
      if (action === 'kick') {
        const participantNames = participantsArr.map(
          participantUid => getContactById(participantUid)?.name,
        )
        participantUuidsToBeKicked.value = participantsArr.join(',')
        if (participantNames.length === 1) { participantsToBeKicked.value = `${participantNames[0]}` }
        if (participantNames.length > 1) {
          participantsToBeKicked.value = `${participantNames
            .slice(0, participantNames.length - 1)
            .join(', ')} and ${participantNames[participantNames.length - 1]}`
        }
      }
      root.$nextTick(() => {
        root.$bvModal.show('group-action-modal')
      })
    }

    // Group Events
    const setGroupName = (roomUid, name = null) => {
      const group = getChatContactByRoomUid(roomUid)
      if (name) {
        group.name = name
        activeChat.value.contact.name = name
      } else {
        group.name = createGroupName(group)
        activeChat.value.contact.name = group.name
      }
    }

    EventBus.$off('room-left')
    EventBus.$on('room-left', data => {
      if (data.participant !== self.uuid) {
        const group = getChatContactByRoomUid(data.roomUid)
        group.name = createGroupName(group)
        activeChat.value.contact.name = group.name
      }
    })

    EventBus.$off('participant-kicked')
    EventBus.$on('participant-kicked', data => {
      if (data.peerUids?.includes(self.uuid)) {
        store.dispatch('chat/removeGroup', data.roomUid)
        if (activeChat.value.contact?.roomUid === data.roomUid) { activeChat.value = {} }
        const chat = getChatContactByRoomUid(data.roomUid)
        chatsContacts.value.splice(chatsContacts.value.indexOf(chat), 1)
      } else setGroupName(data.roomUid, data.name)
    })

    EventBus.$off('participant-added')
    EventBus.$on('participant-added', data => setGroupName(data.roomUid, data.name))

    EventBus.$off('group-renamed')
    EventBus.$on('group-renamed', data => setGroupName(data.roomUid, data.name))

    EventBus.$off('group-deleted')
    EventBus.$on('group-deleted', roomUid => {
      let chatContact = getChatContactByRoomUid(roomUid)
      if (chatContact) {
        chatsContacts.value.splice(chatsContacts.value.indexOf(chatContact), 1)
      } else {
        chatContact = getContactByRoomUid(roomUid)
        contacts.value.splice(contacts.value.indexOf(chatContact), 1)
      }
      if (activeChat.value.contact.roomUid === roomUid) activeChat.value = {}
    })

    // ------------------------------------------------
    // Empty active chat when unmounted
    // ------------------------------------------------
    onUnmounted(() => {
      EventBus.$off('message-received')
      activeChat.value = {}
    })

    return {
      onScroll,
      showScrollDownArrow,
      scrollToBottomInChatLog,
      isLoading,
      self,
      noSidebarOrOnlySidebar,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      sendMessage,

      // TypingEvent,
      onTyping,
      isTyping,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,
      chatType,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // For minimizing Chat
      miniMizeChat,

      // Video and audio calling
      startCall,

      // fetch more chat
      isLoadingChat,
      fetchMoreChat,

      // File
      pushFileToChat,
      storeResource,
      clearFileInputForm,

      // Floating Chat / Sidebar Chat
      showChatOptions,
      onCloseChat,

      // Snapshot
      sendFiles,
      addFile,
      removeFile,
      chatFiles,

      // Audio Message
      recordingAudio,
      audioStream,
      recordTimer,
      audio,
      sendAudio,
      clearAudio,

      // Group Chat
      onMessagePrivately,
      leaveGroupChat,
      kickParticipant,
      groupModalAction,
      showGroupActionModal,
      scrollToUser,
      isGroupChat,
      isGroupOwner,
      groupAction,
      participantsToBeKicked,
      participantUuidsToBeKicked,
      groupActionModalInfo,
      createNewGroup,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat.scss";
@import "@core/scss/base/pages/app-chat-list.scss";
.top-0 {
  top: 0;
}
.options {
  opacity: 0;
}
.chat-title:hover .options {
  opacity: 1;
}
</style>
