/* eslint-disable import/extensions */
import isAuthenticated from '@/middlewares/isAuthenticated'
import projectNotSelected from '@/middlewares/projectNotSelected'
import onlyAdmin from '@/middlewares/onlyAdmin'
import hasPermission from '@/middlewares/hasPermission'

export default [
  {
    path: '/users',
    name: 'users',
    meta: {
      middleware: [isAuthenticated],
    //   breadcrumb: [
    //     {
    //       text: 'Settings',
    //       active: true,
    //     },
    //     {
    //       text: 'Users',
    //       active: true,
    //     },
    //   ],
    },
    component: () => import('@/views/Settings/Users'),

  },
  {
    path: '/company-users',
    name: 'company-users',
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Company User',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/Users/companyUsers.vue'),
  },
  {
    path: '/idle-time',
    name: 'idle-time',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Idle Time',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Idle Time',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Reports/IdleTime/index.vue'),
  },
  {
    path: '/companies',
    name: 'companies',
    meta: {
      middleware: [projectNotSelected],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Company',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/company/main'),
  },
  {
    path: '/companies/:uid/projects',
    name: 'companies-projects',
    meta: {
      middleware: [projectNotSelected],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Company',
          active: true,
        },
        {
          text: 'Projects',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/company/projects'),
  },

  {
    path: '/company-setting',
    name: 'companySetting',
    meta: {
      middleware: [isAuthenticated, hasPermission],
      permissions: ['timezones.update', 'company.edit'],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Company Settings',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/company/main/MyCompany'),
  },
  {
    path: '/profile',
    name: 'pages-profile',
    meta: {
      middleware: [isAuthenticated],
    },
    component: () => import('@/views/common/Profile'),
  },
  {
    path: '/team-report',
    name: 'team-report',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Team Report',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Team Report',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Reports/Team/Report'),
  },
  {
    path: '/team-setting/:uuid',
    name: 'team-setting',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Team',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/Team/Settings/index.vue'),
  },
  {
    path: '/team',
    name: 'team',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Team',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/Team/CRUD'),
  },
  {
    path: '/timeline',
    name: 'timeline',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Weekly Time',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Timeline',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Reports/Timeline'),
  },
  {
    path: '/payments',
    name: 'payments',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Payments',
      breadcrumb: [
        {
          text: 'Payroll',
          active: true,
        },
        {
          text: 'Payments',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Payroll/payments'),
  },
  // {
  //   path: '/payroll-setting',
  //   name: 'payroll-setting',
  //   meta: {
  //     middleware: [isAuthenticated],
  //     breadcrumb: [
  //       {
  //         text: 'Settings',
  //         active: true,
  //       },
  //       {
  //         text: 'Payment Setting',
  //         active: true,
  //       },
  //     ],
  //   },
  //   component: () => import('@/views/Payroll/payments/settings'),
  // },
  {
    path: '/payment-history',
    name: 'payment-history',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Payment History',
      breadcrumb: [
        {
          text: 'Payroll',
          active: true,
        },
        {
          text: 'Payment History',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Payroll/payments/history'),
  },
  {
    path: '/manual-time',
    name: 'manual-time',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Manual Time',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Manual Time',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Reports/manualtime'),
  },
  {
    path: '/upload-application',
    name: 'upload-application',
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
      breadcrumb: [
        {
          text: 'Uploads',
          active: true,
        },
      ],
    },
    component: () => import('@/views/downloads/CRUD'),
  },
  {
    path: '/manage-permissions',
    name: 'manage-permissions',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Manage Permissions',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/ManagePermissions'),
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('@/views/calculator/index.vue'),
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Calculator',
          active: true,
        },
      ],
    },
  },
  {
    path: '/calculator/settings',
    name: 'calculator-settings',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Calculator',
          active: true,
        },
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
    component: () => import('@/views/calculator/components/Settings.vue'),
  },
  {
    path: '/calculator/employees',
    name: 'calculator-employees',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Calculator',
          active: true,
        },
        {
          text: 'Employees',
          active: true,
        },
      ],
    },
    component: () => import('@/views/calculator/components/Employees.vue'),
  },
  {
    path: '/settings/project',
    name: 'project-settings',
    meta: {
      middleware: [isAuthenticated, hasPermission],
      permissions: ['project.manageusers'],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'Project Settings',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Settings/project/Index.vue'),
  },
  {
    path: '/social-media-campaign',
    name: 'social-media-campaign',
    meta: {
      middleware: [isAuthenticated, hasPermission],
      permissions: ['campaigns.create', 'campaigns.edit'],
      breadcrumb: [
        {
          text: 'Social Media Campaign',
          active: true,
        },
      ],
    },
    component: () => import('@/views/SocialMediaCampaign/Index.vue'),
  },
]
