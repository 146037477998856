<template>
  <div class="text-white">
    <label
      v-b-tooltip.hover="$t('Files')"
      class="action-btn centralize cursor-pointer text-white mb-0 mr-1"
      for="resource-input"
    >
      <feather-icon icon="PaperclipIcon" />
    </label>
    <b-form-file
      id="resource-input"
      v-model="file"
      class="d-none"
      @input="previewImage"
    />
  </div>
</template>

<script>
import { BFormFile, VBTooltip } from 'bootstrap-vue'
import useChat from '../../useChat'

const { GET_MSG_TYPE, GET_FILE_ICON } = useChat()

export default {
  components: {
    BFormFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    roomUid: {
      type: String,
      default: () => '',
    },
    clearForm: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      file: null,
      image: null,
      showOverlay: false,
    }
  },
  computed: {
    fileType() {
      if (this.file) return GET_MSG_TYPE('ATTACHMENT', this.file, 'type')
      return null
    },
    fileIcon() {
      if (this.file) return GET_FILE_ICON(this.file.type)
      return null
    },
  },
  watch: {
    file(val) {
      const data = {
        url: this.image,
        file: val,
        type: this.fileType,
      }
      if (val) this.$emit('preview-file', data)
    },
    clearForm: {
      handler(val) {
        if (val) {
          this.file = null
          this.image = null
          this.$nextTick(() => { this.$emit('form-cleared') })
        }
      },
      immediate: true,
    },
  },
  methods: {
    previewImage() {
      if (this.file && this.fileType === 'image') {
        const imageBlob = new Blob([this.file], { type: this.file.type })
        this.image = URL.createObjectURL(imageBlob)
      }
      this.$nextTick(() => {
        this.$bvModal.show('chat-image-preview-modal')
      })
    },
    clearData() {
      this.file = null
      this.image = null
    },
  },
}
</script>
