import gql from 'graphql-tag'
import {
  BankWalletFragment, BankTransferSuccessFragment, WalletToWalletTransferFragment, ReconciliationTransactionFragment, UserVendorFragment, CompanyVendorFragment, VendorFragment,
  BillingInformationFragment,
  AchBillingInformationFragment,
  FxBillingInformationFragment,
  FullBillingInformationFragment,
  FullAchBillingInformationFragment,
  FullFxBillingInformationFragment,
  applicationFragment,
} from '../fragments'

export default {
  COMPANY_OWNER: gql`
    query companyOwner($userId: ID) {
      companyOwner(id: $userId) {
        id
        name
        company {
          id
          name
          users(first: 10) {
            data {
              id
              name
            }
            paginatorInfo {
              total
            }
          }
        }
      }
    }
  `,
  COMPANIES: gql`
    query companies($first: Int, $page: Int) {
      companies(jobProfile: false, first: $first, page: $page) {
        data {
          uuid
          name
          address
          users {
            total
          }
          weekStartDay
          projects {
            total
          }
          extraInformations(
            type: BILLINGCHARGE
            first: 1
          ) {
            data{
              information{
                ... on CompanyBillingCharge {
                  id
                  swiftCharge
                  achCharge
                  fxCharge
                  __typename
                }
              }
            }
          }
          # projects {
          #   data {
          #     uuid
          #     name
          #     description
          #   }
          # }
        }
        perPage
        total
      }
    }
  `,
  SEARCH_COMPANIES: gql`
    query searchCompanies(
      $keyword: [String!]
      $first: Int
      $page: Int
    ) {
      companies(
        company: {
          searchKey: NAME
          keyword: $keyword
        }
        jobProfile: false,
        first: $first,
        page: $page,
      ) {
        data {
          uuid
          name
          address
          users {
            total
          }
          weekStartDay
          projects {
            total
          }
          extraInformations(
            type: BILLINGCHARGE
            first: 1
          ) {
            data{
              information{
                ... on CompanyBillingCharge {
                  id
                  swiftCharge
                  achCharge
                  fxCharge
                  __typename
                }
              }
            }
          }
        }
        perPage
        total
      }
    }
  `,
  GET_COMPANY: gql`
    query getcompany($id: String!) {
      company(uuid: $id) {
        uuid
        name
        address
        currency
        trackingType
        weekStartDay
        timezoneOffset {
          name
          offset
          filter
        }
        timezones {
          data {
            name
          }
        }
      }
    }
  `,
  GET_COMPANY_PAYMENT_GATEWAYS: gql`
  query getcompany($id: String!) {
    company(uuid: $id) {
      uuid
      paymentGateways{
        total
        data{
          alias
          isDefault
          information{
            __typename
            ... on AirwallexBillingInformation{
              url
              id
              clientId
              clientSecret
              transferSuccessKey
              transferCreatedKey
            }
            ... on StripeBillingInformation{
              id
              clientId
              clientSecret
            }
          }
        }
      }
    }
  }
`,
  EDIT_COMPANY: gql`
    query editcompany($id: String!) {
      company(uuid: $id) {
        uuid
        name
        address
        currency
        trackingType
        weekStartDay
        timezoneOffset {
          name
          offset
          filter
        }
        timezones {
          data {
            name
          }
        }
      }
    }
  `,
  COMPANY_USER: gql`
    query companyUsers($uuid: String) {
      company(uuid: $uuid) {
        users {
          data {
            uuid
            name
          }
        }
      }
    }
  `,
  COMPANY_ROLES: gql`
    query companyRoles {
      roles(first: -1) {
        data {
          name
          displayName
        }
      }
    }
  `,
  GET_ALL_ROLES: gql`
    query getRoles {
      projectRoles(first: -1, page: 1) {
        data {
          id
          name
          displayName
        }
      }
      roles(first: -1) {
        data {
          name
          displayName
        }
      }
    }
  `,
  GET_ALL_COMPANY: gql`
    query company {
      companies(first: -1, jobProfile: false) {
        data {
          uuid
          name
        }
      }
    }
  `,
  GET_TIMEZONE: gql`
    query getTimezone($keyword: String!) {
      timezones(
        timezone: { searchKey: NAME, keyword: [$keyword], type: LIKE }
        first: -1
      ) {
        data {
          name
          displayName
          offset
          filter
        }
      }
    }
  `,

  SEARCH_PROJECTS: gql`
    query searchProjects($keyword: String!, $uuid: String!, $first: Int!, $page: Int!) {
      company(uuid: $uuid) {
        projects(
          project: { searchKey: NAME, keyword: [$keyword], type: LIKE }
          first: $first, page: $page) {
          total
          data {
            name
            description
            uuid
            teams {
              total
            }
            users {
              total
            }
          }
        }
      }
    }
  `,

  GET_MY_COMPANY: gql`
    query myCompany($uuid: String) {
      company(uuid: $uuid) {
        uuid
        id
        name
        email
        address
        currency
        trackingType
        weekStartDay
        timezoneOffset {
          name
          displayName
          filter
        }
        managementInfo {
          settings {
            information {
              ... on PercentageBased{
                value
              }
              ... on MonthlyCharge{
                value
              }
            }
          }
        }

        # timezones {
        #   data {
        #     displayName
        #     name
        #     filter
        #   }
        # }
        # paymentSetting {
        #   information {
        #     __typename
        #     ... on CompanyPaymentOnWeek {
        #       weekDay
        #       weekNum
        #     }
        #     ... on CompanyPaymentOnDays {
        #       runDate
        #       numberDays
        #     }
        #     ... on CompanyPaymentOnDate {
        #       day
        #     }
        #     ... on CompanyPaymentOnMonth {
        #       firstDay
        #     }
        #   }
        # }
      }
    }
  `,

  GET_COMPANY_PAYMENT_SETTINGS: gql`
    query getCompanyPaymentSettings(
      $companyUid: String!
    ) {
      company(uuid: $companyUid) {
        paymentSetting {
          information {
            __typename
            ... on CompanyPaymentOnWeek {
              weekDay
              weekNum
            }
            ... on CompanyPaymentOnDays {
              runDate
              numberDays
            }
            ... on CompanyPaymentOnDate {
              day
            }
            ... on CompanyPaymentOnMonth {
              firstDay
            }
          }
        }
      }
    }
  `,

  GET_MY_COMPANY_TIMEZONE: gql`
    query getMyTimeZone($companyUid: String!) {
      me {
        id
        companies(first: 1,company:{ keyword: [$companyUid], searchKey:UUID}) {
          data {
            id
            timezoneOffset {
              displayName
              filter
              name
            }
            timezones {
              data {
                name
                displayName
                filter
              }
            }
          }
        }
      }
    }
  `,

  GET_APPLICATION: gql`
    query applications {
      MAC: applications(
        platforms: [MAC],
        orderBy: {column: CREATED_AT, order:DESC},
        first: 1,
      ) {
        data {
          ...applicationFragment
        }
      }
      WINDOWS: applications(
        platforms: [WINDOWS],
        orderBy: {column: CREATED_AT, order:DESC},
        first: 1,
      ) {
        data {
          ...applicationFragment
        }
      }
      LINUX: applications(
        platforms: [LINUX],
        orderBy: {column: CREATED_AT, order:DESC},
        first: 1,
      ) {
        data {
          ...applicationFragment
        }
      }
    }
    ${applicationFragment}
  `,

  GET_APPLICATION_BUILD: gql`
    query applicationVersions {
      MAC:applicationVersions(
        platforms:MAC
      ) {
        version
      }
      WINDOWS:applicationVersions(
        platforms:WINDOWS
      ) {
        version
      }
      LINUX:applicationVersions(
        platforms:LINUX
      ) {
        version
      }
    }
  `,
  GET_APPLICATION_BUILD_BY_VERSION: gql`
   query builds($version: String!, $platforms: [Platform!]) {
    applications (version: $version, platforms: $platforms, first: -1) {
      data {
        version
        platform
        filename
        description
        createdAt
        architecture
      }
    }
  }
 `,

  GET_APPLICATION_SPECIFIC: gql`
    query applications(
      $platforms: [Platform!]
      $architecture: [Architecture!]
    ) {
      applications(
        platforms: $platforms
        architecture: $architecture
        orderBy: { column: CREATED_AT, order: DESC }
        first: 1
      ) {
        data {
          version
          platform
          filename
          description
          createdAt
          architecture
        }
      }
    }
  `,

  GET_COUNTRIES: gql`
    query getCountries {
      countries(first: -1, page: 1) {
        data {
          name
          code
        }
      }
    }
  `,

  GET_COMPANY_LEADS: gql`
    query getCompanyLeads(
      $first: Int!,
      $page: Int!,
      $status: [CompanyLeadStatus!],
      $keyword: String!,
    ) {
      companyLeads(
        first: $first,
        page: $page,
        status: $status,
        companyLead: {keyword: [$keyword],searchKey:NAME}
        orderBy: { column: PRIORITY, order: DESC }
      ) {
        total
        data {
          uuid
          name
          address
          email
          phone
          status
          priority
          histories {
            data{
              agent {
                avatar
                name
              }
              oldStatus
              newStatus
              description
            }
          }
          contacts {
            id
            name
            email
            phone
            address
          }
        }
      }
    }
  `,
  GET_COMPANY_WALLET: gql`
    query getCompanyWallet(
      $company: String!,
      $familyType: WalletFamilyType
    ) {
      company(uuid: $company) {
        uuid
        name
        walletBalance
        reconBalance
        realBalance
        wallets(
          excludeDefault: false,
          familyType: $familyType,
          orderBy: { column: ORDER, order: ASC }
        ) {
          total
          data {
            uuid
            description
            familyType
            isDefault
            balance
            reconBalance
            realBalance
            shares(
              includeInactive: false
              orderBy: { column: ORDER, order: ASC }
            ) {
              uuid
              description
              active
              balance
              reconBalance
              realBalance 
              sharePercent
              shareDirection
              family {
                uuid
                description
              }
              invites(status:PENDING) {
                total
                data{
                  processedAt
                  accepted
                  checksum
                  sharedTo {
                    ... on User {
                      name
                    }
                  }
                }
              }
              owner {
                ... on User {
                  uuid
                  name
                }
                ... on Company {
                  uuid
                  name
                }
              }
              sharedTo {
                ... on User {
                  uuid
                  name
                }
                ... on Company {
                  uuid
                  name
                }
              }
              wallet {
                ... on BankWallet {
                  information {
                    ...BillingInformationFragment
                    ...AchBillingInformationFragment
                    ...FxBillingInformationFragment
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
          }
        }
      }
    }
    ${BillingInformationFragment}
    ${AchBillingInformationFragment}
    ${FxBillingInformationFragment}
  `,
  GET_COMPANY_TRANSACTIONS: gql`
    query getCompanyTransactions(
      $first: Int,
      $page: Int,
      $startTime: DateTime!,
      $endTime: DateTime!,
      $company: String!,
      $vendorSearchKey: SearchKey,
      $vendorType: VendorType!,
      $vendorKeyword: [String!]
    ) {
      company(uuid: $company) {
        balance:walletBalance(date: $endTime)
        openingBalance:walletBalance(filter:{date:$startTime, first: $first, page: $page})
        walletTransactions(
          first: $first,
          page: $page,
          orderBy: [
            { column: TRANSACTION_DATE, order: ASC}
            { column: ID, order: ASC}
            ]
          between: { startTime: $startTime, endTime: $endTime }
          filter: {
            searchKey: $vendorSearchKey
            vendor:{ vendorType: $vendorType, keyword: $vendorKeyword}
            keyword: $vendorKeyword
          }
        ) {
          total
          data {
            uuid
            amount
            description
            creator {
              uuid
              name
            }
            createdAt
            transactionDate
            walletShare {
              uuid
              description
              wallet {
                ... on InternalWallet {
                  id
                }
                ...BankWalletFragment
              }
            }
            vendorInfo {
              vendor {
                ...UserVendorFragment
                ...CompanyVendorFragment
                ...VendorFragment
              }
            }
            transactionType {
              ...BankTransferSuccessFragment
              ...WalletToWalletTransferFragment
              ...ReconciliationTransactionFragment
            }
          }
        }
      }
    }
    ${UserVendorFragment}
    ${CompanyVendorFragment}
    ${VendorFragment}
    ${BankWalletFragment}
    ${BankTransferSuccessFragment}
    ${WalletToWalletTransferFragment}
    ${ReconciliationTransactionFragment}
  `,

  GET_COMPANY_WALLET_TRANSACTIONS: gql`
  query getTransactions(
    $first: Int,
    $page: Int,
    $startTime: DateTime!,
    $endTime: DateTime!,
    $walletUid: String,
    $company: String!,
    # $vendorSearchKey: SearchKey,
    # $vendorType: VendorType!,
    # $vendorKeyword: [String!]
  ) {
    company(uuid: $company) {
      wallets(walletUid: $walletUid){
        data {
          uuid
          description
          balance:balance(date: $endTime)
          openingBalance:balance(date: $startTime)
          transactions(
            first: $first,
            page: $page, 
            orderBy:{column:TRANSACTION_DATE, order:ASC}
            between:{startTime: $startTime, endTime: $endTime}
            # filter: {
            #   searchKey: $vendorSearchKey
            #   vendor:{ vendorType: $vendorType, keyword: $vendorKeyword}
            #   keyword: $vendorKeyword
            # }
          ) {
            total
            data {
              description
              amount
              createdAt
              transactionDate
              walletShare {
                description
                wallet {
                  ... on InternalWallet {
                    id
                  }
                  ...BankWalletFragment
                }
              }
              vendorInfo {
                vendor {
                  ...UserVendorFragment
                  ...CompanyVendorFragment
                  ...VendorFragment
                }
              }
              transactionType {
                __typename
                ...BankTransferSuccessFragment
                ...WalletToWalletTransferFragment
              }
            }
          }
        }
      }
    }
  }
  ${UserVendorFragment}
  ${CompanyVendorFragment}
  ${VendorFragment}
  ${BankWalletFragment}
  ${BankTransferSuccessFragment}
  ${WalletToWalletTransferFragment}
`,

  GET_COMPANY_SHARE_TRANSACTIONS: gql`
  query getCompanyShareTransactions(
    $company: String!,
    $first: Int,
    $page: Int,
    $startTime: DateTime!,
    $endTime: DateTime!,
    $keyword: [String!],
    $walletUid: String,
    $vendorSearchKey: SearchKey,
    $vendorType: VendorType!,
    $vendorKeyword: [String!]
  ) {
    company(uuid: $company) {
      uuid
      name
      wallets (walletUid: $walletUid) {
        data {
          uuid
          shares(
            share:{keyword: $keyword, searchKey: UUID}
          ) {
            uuid
            balance:balance(date: $endTime)
            openingBalance:balance(filter: { date: $startTime, first: $first, page: $page })
            description
            transactions(
              first: $first,
              page: $page, 
              orderBy:[{column:TRANSACTION_DATE, order:ASC},{column:ID, order:ASC}]
              between:{startTime: $startTime, endTime: $endTime}
              filter: {
                searchKey: $vendorSearchKey
                vendor:{ vendorType: $vendorType, keyword: $vendorKeyword}
                keyword: $vendorKeyword
              }
            ) {
              total
              data {
                uuid
                description
                amount
                createdAt
                transactionDate
                walletShare {
                  uuid
                  description
                  wallet {
                    ... on InternalWallet {
                      id
                    }
                    ...BankWalletFragment
                  }
                }
                vendorInfo {
                  vendor {
                    ...UserVendorFragment
                    ...CompanyVendorFragment
                    ...VendorFragment
                  }
                }
                transactionType {
                  __typename
                  ...BankTransferSuccessFragment
                  ...WalletToWalletTransferFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${UserVendorFragment}
  ${CompanyVendorFragment}
  ${VendorFragment}
  ${BankWalletFragment}
  ${BankTransferSuccessFragment}
  ${WalletToWalletTransferFragment}
`,

  GET_COMPANY_VENDORS: gql`
    query getCompanyVendors(
      $keyword: [String!]
      $companyUid: String!
    ) {
      company( uuid: $companyUid) {
        vendors(filter: {keyword: $keyword, searchKey: NAME}) {
          data {
            vendor {
              ...CompanyVendorFragment
              ...UserVendorFragment
              ...VendorFragment
            }
          }
        }
      }
    }
    ${UserVendorFragment}
    ${CompanyVendorFragment}
    ${VendorFragment}
  `,

  GET_COMPANY_BILLING_INFO: gql`
    query getCompanyBillingInfo(
      $companyUid: String!,
    ) {
      company( uuid: $companyUid) {
        id
        extraInformations(type: [BILLING, ACHBILLING, FXBILLING]) {
          total
          data {
            information {
              ...FullBillingInformationFragment
              ...FullAchBillingInformationFragment
              ...FullFxBillingInformationFragment
            }
          }
        }
      }
    }
    ${FullBillingInformationFragment}
    ${FullAchBillingInformationFragment}
    ${FullFxBillingInformationFragment}
  `,
  GET_BILLING_CHARGES: gql`
    query getBillingCharges {
      workzone{
        extraInformations(type: BILLINGCHARGE){
          data{
            information{
              ... on CompanyBillingCharge {
                id
                swiftCharge
                achCharge
                fxCharge
              }
            }
          }
        }
      }
    }
  `,
  GET_COMPANY_WALLET_SHARES: gql`
  query getCompanyWalletShares(
    $walletUid: String
    $companyUid: String!
  ){
    me {
      companies(company:{searchKey:UUID, keyword: [$companyUid]}){
        data {
          wallets(walletUid:$walletUid){
            data{
              shares{
                uuid
                description
              }
            }
          }
        }
      }
    }
  }
`,
  GET_COMPANY_WALLET_FAMILIES: gql`
  query getCompanyWalletFamilies(
    $companyUid: String!
  ) {
    me {
      companies(company:{searchKey: UUID, keyword: [$companyUid]}){
        data {
          wallets{
            data{
              uuid
              description
            }
          }
        }
      }
    }
  }
`,
  GET_COMPANY_SCHEDULED_TRANSFERS: gql`
    query getCompanyScheduledTransfers(
      $companyUid: String,
      $includeInactive: Boolean,
      $page: Int,
      $first: Int
    ) {
      company(uuid: $companyUid) {
        scheduledTransfers(
          includeInactive: $includeInactive
          page: $page
          first: $first
        ) {
          total
          data {
            uuid
            name
            description
            transferAmount
            transferType
            deactivatedAt
            owner {
              ... on User {
                uuid
                name
              }
            }
            schedule{
              __typename
              ... on CompanyPaymentOnMonth {
                firstDay
              }
              ... on CompanyPaymentOnDate {
                day
              }
              ... on CompanyPaymentOnWeek {
                weekNum
                weekDay
              }
              ... on CompanyPaymentOnDays {
                runDate
                numberDays
              }
            }
            source {
              uuid 
              description
              balance
              wallet {
                __typename
              }
              family {
                __typename
                uuid
                description
              }
            }
            destination {
              ...on WorkzoneWallet {
                __typename
                uuid
                description
              }
              ...on WalletShare {
                __typename
                uuid
                description
                wallet {
                  __typename
                }
                family {
                  uuid
                  description
                }
                sharedFamily{
                  uuid
                  description
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_COMPANY_PAYROLL_WALLETS: gql`
    query getCompanyPayrollWallets($company: String!) {
      company(uuid: $company) {
        uuid
        wallet {
          uuid
          shares(includeInactive: false) {
            uuid
            description
            active
          }
        }
      }
    }
  `,
  GET_COMPANY_ALT_WALLETS: gql`
    query getCompanyAltWallets($company: String!) {
      company(uuid: $company) {
        uuid
        wallets(excludeDefault: true, includeInactive: false) {
          data {
            uuid
            description
            shares {
              uuid
              description
              wallet {
                ...on InternalWallet{
                  __typename
                }
                ...on BankWallet{
                  __typename
                }
              }
            }
          }
        }
      }
    }
  `,
  GET_PROJECT_SETTINGS: gql`
  query getProjectSettings(
    $project:String!
    $type: [ProjectSettingsType!]
  ){
    project(uuid: $project){
      settings(type: $type){
        information{
          ...on ProjectCloudSetting{
            enableCloudService
          }
        }
      }
    }
  }
`,
  GET_COMPANY_CAMPAIGNS: gql`
    query getCompanyCampaigns(
      $companyUid: [String!]
    ) {
      campaigns(company:{
        searchKey: UUID
        keyword: $companyUid
      }){
        data {
          uuid
          name
          purpose
          phases {
            title
          }
          generations(first: -1) {
            total
            data {
              uuid
              title
              description
              post
              imageUrl
              imagePrompt
              scheduledAt
              imagePrompt
              jsonData
              createdAt
            }    
          }
          batches(active:true){
            data {
              id
              child{
                id
                pendingJobs
                totalJobs
              }
            }
          }
          linkedAccounts {
            id
            account {
              ... on CampaignFacebookSetting {
                __typename
              }
            }
          }
          createdAt
        }
      }
    }
  `,
  GET_CAMPAIGN_PROGRESS_BATCH: gql`
    query getCampaignProgressBatch($batchUid: String!) {
      batch(id: $batchUid){
        id
        totalJobs
        pendingJobs
        processedJobs
        progress
        child{
          id
          totalJobs
          pendingJobs
          failedJobs
          processedJobs
          progress
        }
      }
    }
  `,
  GET_COMPANY_MANAGEMENT_FEE: gql`
    query getCompanyManagementFee($companyUid: String!) {
      company(uuid: $companyUid) {
        id
        managementInfo {
          __typename
          settings {
            information {
              __typename
              ... on PercentageBased{
                value
              }
              ... on MonthlyCharge{
                value
              }
              
            }
          }
        }
      }
    }
  `,
}
