<template>
  <VueDraggableResizable
    :resizeable="false"
    :aspect-ratio="false"
    @dragstop="onDragstop"
  >
    <div
      @pointerdown="startTimer"
      @pointerup="triggerButton"
    >
      <slot />
    </div>
  </VueDraggableResizable>
</template>

<script>
import {
  getCurrentInstance, nextTick, reactive, toRefs,
} from 'vue'
import VueDraggableResizable from 'vue-draggable-resizable'

export default {
  components: {
    VueDraggableResizable,
  },
  setup() {
    const instance = getCurrentInstance().proxy
    const state = reactive({
      timer: 0,
    })
    let tourInterval

    // methods
    const startTimer = () => {
      state.timer = 0
      nextTick(() => {
        tourInterval = setInterval(() => {
          state.timer += 10
          instance.$emit('bv::hide::tooltip')
        }, 10)
      })
    }

    const triggerButton = () => {
      window.clearInterval(tourInterval)
      nextTick(() => {
        if (state.timer < 150) {
          instance.$emit('trigger')
        }
        state.timer = 0
      })
    }

    const onDragstop = (left, top) => {
      instance.$emit('drag-stop', { left, top })
    }

    return {
      ...toRefs(state),
      startTimer,
      triggerButton,
      onDragstop,
    }
  },
}
</script>
