<template>
  <div class="sidebar-left">
    <div class="sidebar">

      <!-- Logged In User Profile Sidebar -->
      <user-profile-sidebar
        :shall-show-user-profile-sidebar="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        @close-sidebar="$emit('update:shall-show-user-profile-sidebar', false)"
      />

      <!-- Sidebar Content -->
      <perfect-scrollbar
        ref="scrollbarRef2"
        :options="perfectScrollbarSettings"
        class="sidebar-content list-group scroll-area w-100"
        :class="{'show': mqShallShowLeftSidebar}"
      >
        <!-- Sidebar close icon -->
        <div
          v-if="activeChatContactId"
          class="sidebar-close-icon"
          @click="$emit('update:mq-shall-show-left-sidebar', false)"
        >
          <feather-icon
            icon="XIcon"
            size="16"
          />
        </div>

        <!-- Header -->
        <div class="chat-fixed-search">
          <div class="search-width d-flex align-items-center w-80">
            <div class="sidebar-profile-toggle">
              <b-avatar
                size="42"
                class="cursor-pointer badge-minimal avatar-border-2"
                :src="getAvatar(profileUserMinimalData.avatar)"
                variant="transparent"
                badge
                badge-variant="success"
                @click.native="$emit('show-user-profile')"
              />
            </div>
            <!-- Search -->
            <b-input-group class="search-box input-group-merge ml-1 w-100 round">
              <b-input-group-prepend is-text>
                <feather-icon
                  icon="SearchIcon"
                  class="text-muted"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model="searchQuery"
                :placeholder="$t('Search')"
              />
            </b-input-group>
          </div>
        </div>

        <!-- ScrollArea: Chat & Contacts -->
        <perfect-scrollbar
          ref="scrollbarRef"
          :options="perfectScrollbarSettings"
          class="chat-user-list-wrapper list-group scroll-area"
        >

          <!-- Chats Title -->
          <h4 class="chat-list-title">
            {{ $t('Chats') }}
          </h4>

          <user-skeleton
            v-if="isLoading"
            :count="3"
            class="ml-1"
          />

          <!-- Chats -->
          <ul
            v-else-if="filteredChatsContacts.length"
            class="chat-users-list chat-list media-list"
          >
            <chat-contact
              v-for="contact in filteredChatsContacts"
              :key="contact.roomUid"
              :ref="contact.id"
              :user="contact"
              :contacts="contacts"
              tag="li"
              :class="{'active': activeChatContactId === contact.roomUid}"
              is-chat-contact
              @click="$emit('open-chat', contact.id)"
            />
          </ul>
          <p
            v-else
            class="text-center"
          >
            {{ $t('No chats found!') }}
          </p>

          <!-- Contacts Title -->
          <h4 class="chat-list-title">
            {{ $t('Contacts') }}
          </h4>

          <!-- Contacts -->
          <user-skeleton
            v-if="isLoading"
            :count="5"
            class="ml-1"
          />
          <ul
            v-else-if="filteredContacts.length"
            class="chat-users-list contact-list media-list"
          >
            <chat-contact
              v-for="contact in filteredContacts"
              :key="contact.id"
              :ref="contact.id"
              :user="contact"
              :contacts="contacts"
              :class="{'active': contact.roomUid && activeChatContactId === contact.roomUid}"
              :ongoing-call="ongoingCall"
              tag="li"
              @click="$emit('open-chat', contact.id)"
            />
          </ul>
          <p
            v-else
            class="text-center"
          >
            {{ $t('No contacts found!') }}
          </p>

        </perfect-scrollbar>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<script>
import {
  BAvatar, BInputGroup, BInputGroupPrepend, BFormInput,
} from 'bootstrap-vue'
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { ref, computed } from 'vue'
import ChatContact from './ChatContact.vue'
import UserProfileSidebar from './UserProfileSidebar.vue'
import UserSkeleton from '../common/components/UserSkeleton.vue'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

export default {
  components: {

    // BSV
    BAvatar,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    UserSkeleton,

    // 3rd party
    PerfectScrollbar,

    // SFC
    ChatContact,
    UserProfileSidebar,
  },
  props: {
    chatsContacts: {
      type: Array,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
    shallShowUserProfileSidebar: {
      type: Boolean,
      required: true,
    },
    profileUserData: {
      type: Object,
      required: true,
    },
    profileUserMinimalData: {
      type: Object,
      required: true,
    },
    activeChatContactId: {
      type: String,
      default: null,
    },
    mqShallShowLeftSidebar: {
      type: Boolean,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    scrollToUser: {
      type: String,
      default: () => '',
    },
    newlyCreatedGroup: {
      type: String,
      default: () => '',
    },
    ongoingCall: {
      type: String,
      default: () => null,
    },
  },
  watch: {
    scrollToUser: {
      handler(val) {
        if (val && this.$refs[val]) {
          this.$refs[val][0].$el.scrollIntoView()
        }
      },
      immediate: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
      // wheelPropagation: false,
    }

    const resolveChatContact = userId => props.contacts.find(contact => contact.id === userId)

    // Search Query
    const searchQuery = ref('')

    const findUser = user => user?.name.toLowerCase().includes(searchQuery.value.toLowerCase())
    const searchContactFilterFunction = contact => findUser(contact)
    const filteredChatsContacts = computed(() => props.chatsContacts.filter(searchContactFilterFunction))
    const filteredContacts = computed(() => props.contacts.filter(searchContactFilterFunction).filter(contact => !filteredChatsContacts.value.map(chat => chat.roomUid).includes(contact.roomUid)))

    return {
      // Search Query
      searchQuery,
      filteredChatsContacts,
      filteredContacts,

      // UI
      perfectScrollbarSettings,
      resolveChatContact,
    }
  },
}
</script>
