/* eslint-disable import/extensions */
import isAuthenticated from '@/middlewares/isAuthenticated'
import onlyAdmin from '@/middlewares/onlyAdmin'
import projectNotSelected from '@/middlewares/projectNotSelected'
import i18n from '@/libs/i18n'

const _t = path => i18n.t(`${path}`)
export default [

  {
    path: '/screen-casts',
    name: 'screen-casts',
    meta: {
      middleware: [isAuthenticated],
      enableDropdown: false,
      pageTitle: 'Screenshots',
      breadcrumb: [
        {
          text: _t('Screenshots'),
          active: true,
        },
      ],
    },
    component: () => import('@/views/Screencasts'),

  },

  {
    path: '/hours-tracked',
    name: 'hours-tracked',
    meta: {
      middleware: [isAuthenticated],
      enableDropdown: false,
      pageTitle: 'Hours Tracked',
      breadcrumb: [
        {
          text: 'Reports',
          active: false,
        },
        {
          text: 'Hours Tracked',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Reports/HoursTracked'),
  },

  {
    path: '/attendance',
    name: 'attendance',
    pageTitle: 'Attendance',
    meta: {
      middleware: [isAuthenticated],
      pageTitle: 'Attendance',
      breadcrumb: [
        {
          text: 'Reports',
          active: true,
        },
        {
          text: 'Attendance',
          active: true,
        },
      ],
    },
    component: () => import('@/views/Attendance'),
  },
  {
    path: '/profile',
    name: 'pages-profile',
    meta: {
      middleware: [isAuthenticated],
    },
    component: () => import('@/views/common/Profile'),
  },
  {
    path: '/general-settings',
    name: 'user-project-settings',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'General Settings',
          active: true,
        },
      ],
    },
    component: () => import('@/views/common/UserSettings'),
  },
  {
    path: '/general-settings',
    name: 'user-general-settings',
    meta: {
      middleware: [isAuthenticated],
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
        {
          text: 'General Settings',
          active: true,
        },
      ],
    },
    component: () => import('@/views/common/UserSettings'),
  },
  {
    path: '/select-project-roadblock',
    name: 'select-project-roadblock',
    meta: {
      middleware: [projectNotSelected],
    },
    component: () => import('@/@core/layouts/components/ProjectSelectRoadBlock.vue'),
  },

  {
    path: '/cms-services',
    name: 'cms-services',
    meta: {
      middleware: [isAuthenticated, onlyAdmin],
    },
    component: () => import('@/views/common/cms/Services.vue'),
  },
]
